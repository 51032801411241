/* Panels */
@keyframes panel {
    0% { transform: scale(100%) rotateY(0deg); }
    50% { transform: scale(90%) rotateY(180deg); }
    100% { transform: scale(100%) rotateY(0deg); }
}
/* Gears */
@keyframes gearTurn {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(30deg); }
    100% { transform: rotate(60deg); }
}
@keyframes gearTurn2 {
    0% { transform: rotate(30deg); }
    50% { transform: rotate(60deg); }
    100% { transform: rotate(90deg); }
}
@keyframes gearTurn3 {
    0% { transform: rotate(60deg); }
    50% { transform: rotate(30deg); }
    100% { transform: rotate(0deg); }
}
/* Stack */
@keyframes stack1 {
    0% { top: -10vh; opacity: 0; }
    25% { top: 14.5vh; opacity: 1; }
    75% { top: 14.5vh; opacity: 1; }
    70% { top: 14.5vh; }
    100% { top: 35vh; opacity: 0; }
}
@keyframes stack2 {
    0% { top: -10vh; opacity: 0; }
    25% { top: 8.5vh; opacity: 1; }
    75% { top: 8.5vh; opacity: 1; }
    70% { top: 8.5vh; }
    100% { top: 35vh; opacity: 0; }
}
@keyframes stack3 {
    0% { top: -10vh; opacity: 0; }
    25% { top: 5.5vh; opacity: 1; }
    75% { top: 5.5vh; opacity: 1; }
    70% { top: 5.5vh; }
    100% { top: 35vh; opacity: 0; }
}
@keyframes stack4 {
    0% { top: -10vh; opacity: 0; }
    25% { top: 5.5vh; opacity: 1; }
    75% { top: 5.5vh; opacity: 1; }
    70% { top: 5.5vh; }
    100% { top: 35vh; opacity: 0; }
}
@keyframes stack5 {
    0% { top: -10vh; opacity: 0; }
    25% { top: 2.5vh; opacity: 1; }
    75% { top: 2.5vh; opacity: 1; }
    70% { top: 2.5vh; }
    100% { top: 35vh; opacity: 0; }
}
/* Manage */
@keyframes computer1 {
    0% { transform: scale(0%); }
    20%, 30% { transform: scale(100%); background-image: url(../media/icons/computer.svg); }
    50% { background-image: url(../media/icons/computer2.svg); }
    60% { transform: scale(100%); }
    80%, 100% { transform: scale(0%); background-image: url(../media/icons/computer2.svg); }
}
@keyframes computer2 {
    0% { transform: scale(0%); }
    20%, 40% { transform: scale(100%); background-image: url(../media/icons/computer.svg); }
    50% { transform: scale(100%); background-image: url(../media/icons/computer3.svg); }
    90% { transform: scale(0%); }
    60% { transform: scale(100%); }
    100% { transform: scale(0%); background-image: url(../media/icons/computer3.svg); }
}
@keyframes hub {
    0% { height: 0; transform: scale(100%); }
    25% { height: 8vh; }
    50% { height: 8vh; top: 7vh; }
    75% { height: 0; top: 15vh; }
    100% { height: 0; top: 15vh; transform: scale(100%); }
}
/* Light Bulb */
@keyframes capThrow1 {
    0% {
    }
    100% {
        transform: translateX(25vh);
    }
}
@keyframes capThrow2 {
    0% { opacity: 0%; }
    1% {
        bottom: -10vh;
        transform: rotate(0deg);
        opacity: 100%;
    }
    99% {
        bottom: 40vh;
        transform: rotate(-850deg);
        opacity: 100%;
    }
    100% { opacity: 0%; }
}
@keyframes bulbShake {
    0% { transform: rotate(0deg); }
    5% { transform: rotate(5deg); }
    10% { transform: rotate(-10deg); }
    15% { transform: rotate(5deg); }
    20% { transform: rotate(-10deg); }
    25%, 100% { transform: rotate(0deg); }
}
@keyframes flicker {
    0%, 25%, 100% {
        transform: scale(0%);
    }
    50% { transform: scale(100%); }
    60% { transform: scale(90%); }
    70% { transform: scale(95%); }
    80% { transform: scale(80%); }
    90% { transform: scale(100%); }
}
/* Music */
/* Uses JavaScript */