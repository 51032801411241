/* Default Theme */
.CS0, .CS1, .CS4, .CS5 {
    --l-head: #FFF;
    --d-head: #333333;
    --l-bkg: #FFF;
    --l-bkg-glass: #EFEFFE80;
    --d-txt: #1B1D23;
    --d-bkg: #282c34;
    --d-bkg-glass: #282c3480;
    --l-txt: #F3F4F6;
    --l-hover: #EEEEF0;
    --d-hover: #55555F;
    --hover: #0080d8;
    --hovera: #0081d6;
    --hoverb: #d63600;
    --light: rgb(211, 211, 211);
    --accent1: rgb(145, 145, 145);
    --accent2: rgb(214, 214, 214);
    --light-glass: rgba(211, 211, 211, 0.502);
    --accent1-glass: rgba(145, 145, 145, 0.502);
    --accent2-glass: rgba(214, 214, 214, 0.502);
}