@keyframes fliph5Back {
    0% {
        height: 40%;
        transform: rotateY(180deg);
        backdrop-filter: blur(0vh);
        background-image: linear-gradient(to top, #000E, #000E);
        border-radius: 2.75vh 2.75vh 0 0;
    }
    99% {
        height: 40%;
        transform: rotateY(180deg);
        background-image: linear-gradient(to top, #000E, #000E);
        border-radius: 2.75vh 2.75vh 0 0;
    }
    100% {
        height: 100%;
        transform: rotateY(0deg);
        backdrop-filter: blur(1vh);
        background-image: linear-gradient(to top, #000, #0000);
        border-radius: 2.75vh;
    }
}
@keyframes fliph5 {
    0% {
        height: 100%;
        transform: rotateY(0deg);
        backdrop-filter: blur(0vh);
        background-image: linear-gradient(to top, #000, #0000);
        border-radius: 2.75vh;
    }
    99% {
        height: 100%;
        transform: rotateY(0deg);
        background-image: linear-gradient(to top, #000, #0000);
        border-radius: 2.75vh;
    }
    100% {
        height: 40%;
        transform: rotateY(180deg);
        backdrop-filter: blur(1vh);
        background-image: linear-gradient(to top, #000E, #000E);
        border-radius: 2.75vh 2.75vh 0 0;
    }
}
@keyframes flipCardBack {
    0% {
        transform: rotateY(180deg);
        box-shadow: 2vh 2vh 0 #0003;
        bottom: 0;
    }
    50% {
        bottom: 3vh;
        box-shadow: 5vh 5vh 0 #0003;
    }
    100% {
        transform: rotateY(0deg);
        box-shadow: -2vh 2vh 0 #0003;
        bottom: 0;
    }
}
@keyframes flipCard {
    0% {
        transform: rotateY(0deg);
        box-shadow: -2vh 2vh 0 #0003;
        bottom: 0;
    }
    50% {
        bottom: 3vh;
        box-shadow: 5vh 5vh 0 #0003;
    }
    100% {
        transform: rotateY(180deg);
        box-shadow: 2vh 2vh 0 #0003;
        bottom: 0;
    }
}
@keyframes flipContentBack {
    0% {
        display: inherit;
    }
    50% {
        display: none;
    }
    100% {
        display: none;
    }
}
@keyframes flipContent {
    0% {
        display: none;
    }
    50% {
        display: none;
    }
    100% {
        display: inherit;
    }
}
.pItemHitbox:hover .pItem {
    box-shadow: 0 0 1vh #fff;
}
.pItemHitbox {
    position: absolute;
    top: 17.5%;
    aspect-ratio: 9/16;
    width: 20%;
    transition: all 1s;
}
.pItemHitbox:has(.clicked) .pItemWrap {
    animation: flipCard 0.5s linear 0s 1 forwards;
}
.pItemWrap {
    height: 100%;
    aspect-ratio: 2 / 3;
    position: relative;
    background-color: var(--accent1);
    text-decoration: none;
    color: var(--l-txt);
    border-radius: 3vh;
    display: flex;
    -webkit-user-drag: none;
    animation: flipCardBack 0.5s linear 0s 1 backwards;
    transition: all 1s;
    box-shadow: -2vh 2vh 0 #0003;
}


.pImg {
    flex-basis: 100%;
    flex-grow: 0;
    width: 100%;
    animation: fliph5Back 0.25s linear 0s 1 forwards;
    transition: all 0s;
    transition-delay: 0.25s;
}
.clicked .pImg {
    flex-basis: 4vh;
}
.pItemHitbox:has(.clicked) .pImg {
    animation: fliph5 0.25s linear 0s 1 forwards;
}

.pItemWrap:has(.placeholder) { background: transparent url(../media/images/construction.jpg) center / cover no-repeat; }
.pItemWrap:has(.tmcf) { background: transparent url(../media/images/sourSkittles.jpg) center / cover no-repeat; }
.pItemWrap:has(.emce) { background: transparent url(../media/images/NEW-EMCE.jpg) center / cover no-repeat; }
.pItemWrap:has(.mergechess) { background: transparent url(../media/images/mergechess.jpg) center / cover no-repeat; }
.pItemWrap:has(.t2me) { background: transparent url(../media/images/t2meLogo.png) center / 90% no-repeat; }
.pItemWrap:has(.lasp) { background: transparent url(../media/images/larsonsPantry.webp) center / 90% no-repeat; }


.pItem {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(0px);
    border-radius: 3vh;
    transition: box-shadow 0.25s 0.25s;
    cursor: pointer;
    transition: all 0.25s;
    transition-delay: 0.25s;
}
.pItem.clicked {
    backdrop-filter: blur(2px);
    box-shadow: 0 0 5vh #fff;
}

.pImg h5 {
    padding: 2vh;
    padding-left: 3vh;
    height: 100%;
    width: 100%;
    background-color: #0000;
    transition: 0s 0.25s;
    text-shadow: 0.25vh 0.25vh 1vh #000;
    display: flex;
    align-items: end;
}
.pItem .pContent::selection, .pItem h5::selection {
    background-color: var(--l-bkg);
    color: var(--d-txt);
}
.pItem .pContent {
    flex-grow: 1;
    padding-left: 2vh;
    padding-right: 2vh;
    border-radius: 0 0 3vh 3vh;
    position: relative;
    background-color: #000E;
    user-select: text;
    transform: rotateY(180deg);
    animation: flipContentBack 0.5s linear 0s 1 forwards;
}
.pItem.clicked .pContent { animation: flipContent 0.5s linear 0s 1 forwards; }

.pContent {
    display: flex;
    flex-direction: column;
}
.pContent p {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.pItem a {
    flex-basis: 8vh;
    margin-bottom: 3vh;
    padding: 2vh;
    padding-left: 3vh;
    padding-right: 3vh;
    background: var(--accent1-glass);
    transition: background 0.25s, bottom 1s;
    border-radius: 6vh;
    text-decoration: none;
    color: var(--l-txt);
    user-select: none;
    -webkit-user-drag: none;
}
.pItem a:hover { background-color: var(--light); }
.pItem a:active { background-color: var(--accent1); }