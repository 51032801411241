/* ----------------------------------------------------------------------------------- Global -------------------------------------------------------------------------------------- */

@font-face {
    font-family: "Poppins";
    src: url(../media/fonts/Poppins/Poppins-Medium.ttf);
}
* {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}
body, #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
}
.App {
    position: relative;
    width: 99vw;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--l-bkg);
    color: var(--d-txt);
}
.App::-webkit-scrollbar {
    width: 0;
}
.dmode { background-color: var(--d-bkg); color: var(--l-txt); }

/* --- Text Selection styling --- */
::selection {background-color: var(--d-txt); color: var(--l-txt); }
.dmode ::selection { background-color: var(--l-txt); color: var(--d-txt); }

/* ----------------------------------------------------------------------------------- Coming Soon Page ---------------------------------------------------------------------------- */
#root:has(#comingSoon) {
    height: 100vh;
    background: transparent url(../media/images/comingSoon.jpg) center center / cover;
}
#comingSoon {
    padding-top: 15%;
    padding-bottom: 15%;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    font-size: 5vw;
}
#comingSoon > div {
    margin-right: 15%;
    margin-left: 15%;
    padding: 4vh;
    background: #FFFC;
    backdrop-filter: blur(3px);
    border-radius: 4vh;
}

/* ----------------------------------------------------------------------------------- Back To Top Button -------------------------------------------------------------------------- */
.bttBtnWrapper {
    width: 100%;
    display: flex;
}
.bttBtn {
    position: fixed;
    bottom: 14vh;
    right: 3vh;
    z-index: 1000;
    width: 10vh;
    height: 10vh;
    border-radius: 100%;
    user-select: none;
    cursor: pointer;
    border: none;
    background: var(--hover) url(../media/icons/uparrow.svg) center 10vh;
    background-size: 100%;
    transition: 0s;
    box-shadow: 0.5vh 0.5vh 0.5vh rgba(0, 0, 0, 0.5);
    transition: right 0.25s;
}
.bttBtn:hover {
    background: var(--hover) url(../media/icons/uparrow.svg) center 0vh;
    background-size: 100%;
    transition: background 1s;
}
.bttBtn:active {
    box-shadow: 0vh 0vh 0.5vh rgba(0, 0, 0, 0.5);
    right: 2.5vh;
    bottom: 13.5vh;
    transition: 0s;
}

.bttBtnWrapper.bttHidden, .bttBtnWrapper.bttHidden .bttBtn {
    right: -11vh;
    transition: right 0.25s;
}

/* ----------------------------------------------------------------------------------- Section ------------------------------------------------------------------------------------- */
.section {
    text-shadow: -0.1vh 0.1vh 0.1vh var(--d-txt);
    color: var(--l-bkg);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: background 0.25s;
    z-index: 1;
}
.dmode .section {
    text-shadow: -0.1vh 0.1vh 0.1vh var(--l-txt);
    color: var(--d-bkg);
}
.section.light { background: var(--light); }
.section.accent1 { background: var(--accent1); }
.section.accent2 { background: var(--accent2); }
.section.lightGlass { background: var(--light-glass); }
.section.accent1Glass { background: var(--accent1-glass); }
.section.accent2Glass { background: var(--accent2-glass); }



.section.img1 {
    background: var(--light-glass) url(../media/images/desktop-w.jpg) center center no-repeat fixed;
    background-size: cover;
}
.section.img1 .sectionWrap {
    background: linear-gradient(to bottom, var(--l-bkg-glass), var(--light-glass) 50%);
    backdrop-filter: brightness(100%);
}
.dmode .section.img1 .sectionWrap {
    background: linear-gradient(to bottom, var(--d-bkg-glass), var(--light-glass) 50%);
    backdrop-filter: brightness(40%);
}
.section.img2 { background: var(--accent2-glass) url(../media/images/wireframe.jpg) center center no-repeat fixed; }
.section.img2 .sectionWrap {
    background: linear-gradient(to bottom, var(--l-bkg-glass), var(--accent2-glass), var(--l-bkg) 99%);
}
.dmode .section.img2 .sectionWrap {
    background: linear-gradient(to bottom, var(--d-bkg-glass), var(--accent2-glass), var(--d-bkg) 99%);
    backdrop-filter: brightness(40%);
}
.section.img3 .sectionWrap {
    background: linear-gradient(to bottom, var(--l-bkg-glass), var(--l-bkg) 99%);
    backdrop-filter: blur(0.5vh) invert(1);
    transition: all 0.5s;
}
.section.img3 {background: transparent url(../media/images/placeholder.jpg) center center no-repeat fixed; }
.dmode .section.img3 .sectionWrap {
    background: linear-gradient(to bottom, var(--d-bkg-glass) 0%, var(--d-bkg) 99%);
    transition: all 1s;
    backdrop-filter: brightness(40%);
}
.section.img3 h4 {
    color: var(--d-txt);
    text-shadow: none;
    font-weight: bold;
}
.dmode .section.img3 h4 { color: var(--l-txt); }

/* --- Hovering over a card in the img3 section changes the section overlay color --- */
[class^="colorBkg-"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    filter: opacity(0%);
    transition: all 0.5s;
}

:where(.img1, .img2, .img3) .sectionWrap > .card {
    background-color: var(--l-bkg);
    box-shadow: -1vh 1vh 0 var(--l-bkg-glass);
    color: var(--d-txt);
    text-shadow: none;
}
.dmode :where(.img1, .img2, .img3) .sectionWrap > .card {
    background-color: var(--d-bkg);
    box-shadow: -1vh 1vh 0 var(--d-bkg-glass);
    color: var(--l-txt);
    text-shadow: none;
}
.sectionWrap {
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    flex-grow: 1;
    padding: 7vh;
    display: flex;
    flex-wrap: wrap;
}
h4 {
    margin-top: 5vh;
    font-size: 4vh;
    margin-bottom: 1vh;
    border-bottom: 0.5vh dashed var(--d-txt) !important;
}
.dmode h4 { border-bottom: 0.5vh dashed var(--l-txt) !important; }
h4:first-child { margin-top: 0; }
.section h4 { border-bottom: 0.5vh dashed var(--l-bkg); }
.section h2 {
    text-shadow: -0.1vh 0.1vh 0.1vh var(--l-txt);
    color: var(--d-bkg);
}
.dmode .section h2 {
    text-shadow: -0.1vh 0.1vh 0.1vh var(--d-txt);
    color: var(--l-bkg);
}

/* ----------------------------------------------------------------------------------- Inline Buttons------------------------------------------------------------------------------- */
.inlineBtn {
    transition: all 0.25s;
    border-bottom: 0.5vh dashed var(--hover);
}
.inlineBtn:hover {
    cursor: pointer;
    background: var(--light-glass);
    border-bottom: 0.5vh dashed #0000;
}
.hoverableText:hover {
    color: var(--hover);
}
.text .sectionWrap > .card {
    background-color: var(--accent1);
    z-index: 1;
}
.transparent {
    background-color: var(--l-bkg-glass);
    color: var(--d-txt);
    text-shadow: none;
}
.dmode .transparent {
    background-color: var(--d-bkg-glass);
    color: var(--l-txt);
    text-shadow: none;
}
.transparent .sectionWrap {
    background-color: #0000;
}
.transparent .sectionWrap > .card { background-color: var(--l-bkg); }
.dmode .transparent .sectionWrap > .card { background-color: var(--d-bkg); }
.card { padding: 2vh; border-radius: 2vh; transition: all 0.5s;}
.portfolioGrid { background-color: transparent; align-items: center; }
.cardViewer { padding: 2vh; }
.cardViewer .card {
    padding: 2vh;
    color: var(--l-txt);
    text-shadow: -0.1vh 0.1vh 0 var(--d-bkg);
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
}
.skillGrid { background-color: transparent; }

/* ----------------------------------------------------------------------------------- Social Media Bar ----------------------------------------------------------------------------- */
.socialBtn {
    height: 10vh;
    aspect-ratio: 1 / 1;
    background: transparent;
    border-radius: 100%;
    transition: all 0.25s ease-in;
    -webkit-user-drag: none;
    background-color: var(--l-head);
}
.dmode .socialBtn { filter: invert(100%); }
.linkedIn { background: transparent url(../media/icons/linkedIn.svg) center/80% no-repeat; }
.linkedIn:hover { background: transparent url(../media/icons/linkedIn.svg) center/100% no-repeat; }
.linkedIn:active { background: transparent url(../media/icons/linkedIn.svg) center/90% no-repeat; }
.github { background: transparent url(../media/icons/github.svg) center/80% no-repeat; }
.github:hover { background: transparent url(../media/icons/github.svg) center/100% no-repeat; }
.github:active { background: transparent url(../media/icons/github.svg) center/90% no-repeat; }
.youTube { background: transparent url(../media/icons/youTube.svg) center/80% no-repeat; }
.youTube:hover { background: transparent url(../media/icons/youTube.svg) center/100% no-repeat; }
.youTube:active { background: transparent url(../media/icons/youTube.svg) center/90% no-repeat; }
.socialBar {
    padding: 1vh;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    background-color: var(--l-bkg);
}
.dmode .socialBar { background-color: var(--d-bkg); }

/* ----------------------------------------------------------------------------------- Contact Form --------------------------------------------------------------------------------- */
label {
    flex-basis: 100%;
    display: flex;
    flex-wrap: nowrap;
    color: var(--d-txt);
    position: relative;
}
.dmode label { color: var(--l-txt); }
input, textarea {
    margin-left: 2vh;
    padding: 1vh;
    padding-top: 1.5vh;
    padding-bottom: 0;
    flex-grow: 1;
    background-color: transparent;
    border-top: 0.5vh solid #0000;
    border-bottom: 0.5vh solid var(--light);
    border-left: 0.5vh solid var(--light);
    border-right: 0.5vh solid #0000;
    border-radius: 0 0 0 2vh;
    font-family: inherit;
    font-size: inherit;
    outline: none !important;
    transition: all 0.25s;
}
.dmode input, .dmode textarea { color: var(--l-txt) !important; }
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus, 
textarea:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--d-txt);
}
.dmode :where(textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus, 
textarea:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active) { -webkit-text-fill-color: var(--l-txt) !important; }
:where(input, textarea):focus {
    border: solid var(--accent1) 0.5vh;
    border-top: 0.5vh solid #0000;
    border-radius: 0 0 2vh 0vh;
}
label:has(textarea) { flex-wrap: wrap; }
textarea {
    margin-top: 0.5vh;
    resize: none;
    width: 100%;
    height: 20vh;
    overflow-y: scroll;
    color: var(--d-txt);
}
.dmode textarea { color: var(--l-txt); }
::-webkit-scrollbar {
    width: 1vw;
    background-color: #0000;
}
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 2vh;
    cursor: default;
    transition: all 0.25s;
}
::-webkit-scrollbar-thumb:hover { background-color: #999; }
::-webkit-scrollbar-thumb:active { background-color: #AAA; }
label span {
    position: absolute;
    top: 0vh;
    left: 4vh;
    font-size: 2vh;
    user-select: none;
}
.dmode label span { background-color: var(--d-bkg); }
form button {
    padding: 2vh;
    padding-left: 5vh;
    padding-right: 5vh;
    background-color: transparent;
    border: 0.5vh solid var(--d-txt);
    border-radius: 5vh;
    font-size: inherit;
    font-family: inherit;
    color: var(--d-txt);
    cursor: pointer;
    transition: 0.25s;
}
.dmode form button {
    color: var(--l-txt);
    border-color: var(--l-txt);
}
.lmode form button:where(:hover, :focus),
.dmode form button:where(:hover, :focus) {
    outline: none;
    border-color: var(--hover);
    background-color: transparent;
    color: var(--hover);
}
form button:active {
    background-color: var(--hover);
    color: var(--l-bkg);
    border-color: var(--hover);
}
.dmode form button:active { color: var(--d-bkg); }

/* ----------------------------------------------------------------------------------- Portfolio Grid ------------------------------------------------------------------------------ */
.sectionWrap:has(.portfolioGrid) {
    padding-left: 0;
    padding-right: 0;
    background-color: var(--d-bkg);
}
.sectionWrap:has(.portfolioGrid) h4 {
    position: absolute;
    left: 12.5%;
    text-align: left;
    width: 75%;
}
.sectionWrap:has(.portfolioGrid) h4 {
    color: var(--l-txt);
    border-color: var(--l-txt) !important;
}
.pGridScroll {
    width: 20%;
    height: 80vh;
    position: absolute;
    top: 20vh;
    left: 0;
    z-index: 100;
    background: transparent url(../media/icons/leftArrow.svg);
    background-size: 75%;
    background-position: right 30%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.25s;
    filter: opacity(40%);
}
.pGridScroll.right {
    background-image: url(../media/icons/rightArrow.svg) !important;
    background-position: left 30%;
    left: unset;
    right: 0;
}
.pGridScroll:hover { background: transparent url(../media/icons/leftArrow.svg) 30% 30% / 70% no-repeat; filter: opacity(100%); }
.pGridScroll.right:hover { background: transparent url(../media/icons/rightArrow.svg) 70% 30% / 70% no-repeat; filter: opacity(100%); }
.pGridScroll:active { background: transparent url(../media/icons/leftArrow.svg) 25% 30% / 60% no-repeat; filter: opacity(50%); }
.pGridScroll.right:active { background: transparent url(../media/icons/rightArrow.svg) 75% 30% / 60% no-repeat; filter: opacity(50%); }

.pfpInner {
    width: 100%;
    height: 100%;
    background-color: #0005;
    position: relative;
    z-index: 1;
    border-radius: inherit;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    backdrop-filter: saturate(0%);
    background-color: rgba(0, 0, 0, 0.01);
    transition: clip-path 0.25s ease-out;
}