/* .cursor {
    animation: blink 1s step-end infinite;
}
@keyframes blink {
    from, to {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
} */

#hero {
    width: 100%;
    height: 88vh;
    position: relative;
    display: flex;
}
.heroSpacer {
    flex-basis: 10%;
}
.heroImgBkg {
    height: 88vh;
    flex-basis: 49.5vh;
    background-image: linear-gradient(to bottom, var(--l-txt), var(--l-bkg));
    box-shadow: 0 -0.5vh 1vh #0003 inset;
    transition: all 1s;
}
.dmode .heroImgBkg {
    background-image: linear-gradient(to bottom, var(--d-txt), var(--d-bkg));
}
.pfp {
    width: 100%;
    height: 100%;
    background-image: url(../media/images/pfp.png);
    background-attachment: fixed;
    background-size: contain;
    background-position: 12.5% 10vh;
    background-repeat: no-repeat;
    filter: grayscale(1);
}
.heroContainer {
    height: 88vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.typeBoxContainer {
    flex-basis: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.typeBox {
    font-family: 'Courier New', Courier, monospace;
    font-size: 5vh;
    font-weight: bold;
    background-color: #c0c4ce;
    width: 75%;
    text-align: left;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0.5vh 0.5vh #0005;
}
.dmode .typeBox {
    background-color: #545d6d;
}
.typeBox .typeText {
    flex-grow: 1;
}
.typeBox .caret {
    flex-grow: 0;
    padding-left: 1vh;
    padding-right: 2vh;
    user-select: none;
}

.ctaBtnWrap {
    flex-grow: 1;
    width: 100%;
    display: flex;
    justify-content: center;
}
.ctaBtn {
    width: 10vh;
    height: 10vh;
    border-radius: 100%;
    user-select: none;
    cursor: pointer;
    border: none;
    background: var(--hover) url(../media/icons/downarrow.svg) center 0.5vh;
    background-size: 100%;
    transition: 0s;
    box-shadow: 0vh 0.5vh 0.5vh rgba(0, 0, 0, 0.5);
}
.ctaBtn:hover {
    background: var(--hover) url(../media/icons/downarrow.svg) center 10.5vh;
    background-size: 100%;
    transition: all 0s;
    transition: background 1s;
}
.ctaBtn:active {
    position: relative;
    top: 0.5vh;
    box-shadow: 0vh 0vh 0.5vh rgba(0, 0, 0, 0.5);
}