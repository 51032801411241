/* Panel Styling */
.panelA, .panelB, .panelC, .panelD, .panelE {
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -20%;
    background-color: var(--hover);
    filter: grayscale(1);
    transition: filter 1s;
}
.panelA { left: 00%; border-radius: 3vh 0 0 3vh; }
.panelB { left: 20%; }
.panelC { left: 40%; }
.panelD { left: 60%; }
.panelE { left: 80%; border-radius: 0 3vh 3vh 0; }
.pSkillItemHitbox:hover .panelA, .pSkillItemHitbox:hover .panelB, .pSkillItemHitbox:hover .panelC, .pSkillItemHitbox:hover .panelD, .pSkillItemHitbox:hover .panelE {
    animation: panel 1s ease-in-out;
    filter: grayscale(0);
}
.pSkillItemHitbox:hover .panelB {
    animation-delay: 0.25s;
    transition-delay: 0.25s;
}
.pSkillItemHitbox:hover .panelC {
    animation-delay: 0.5s;
    transition-delay: 0.5s;
}
.pSkillItemHitbox:hover .panelD {
    animation-delay: 0.75s;
    transition-delay: 0.75s;
}
.pSkillItemHitbox:hover .panelE {
    animation-delay: 1s;
    transition-delay: 1s;
}

/* Hitbox */
.pSkillItemHitbox {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    transition: all 1s;
}
.gearA, .gearB, .gearC, .gearD, .gearE {
    height: 35vh;
    width: 35vh;
    position: absolute;
    top: -15vh;
    left: -15vh;
    background-image: url(../media/icons/gear.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    animation: gearTurn 2s infinite;
}
.gearB {
    height: 30vh;
    width: 30vh;
    top: unset;
    left: unset;
    right: -12vh;
    bottom: -12vh;
    transform: rotate(30deg);
    animation: gearTurn2 2s infinite;
    animation-delay: 0.5s;
}
.gearC {
    height: 10vh;
    width: 10vh;
    top: unset;
    bottom: 0vh;
    left: 3vh;
    animation-name: gearTurn2;
}
.gearD {
    height: 10vh;
    width: 10vh;
    top: unset;
    bottom: -4vh;
    left: 10vh;
    animation: gearTurn3 2s 0s infinite;
}
.gearE {
    height: 20vh;
    width: 20vh;
    top: -10vh;
    left: unset;
    right: 10vh;
    animation: gearTurn3 2s 0.25s infinite;
}

.pSkillItemHitbox:hover .stackA, .pSkillItemHitbox:hover .stackB, .pSkillItemHitbox:hover .stackC, .pSkillItemHitbox:hover .stackD, .pSkillItemHitbox:hover .stackE {
    background-color: var(--l-txt);
    width: 11vh;
    height: 2vh;
    border-radius: 1vh;
    position: absolute;
    top: -10vh;
    right: 3vh;
    animation: stack1 3s 0.2s ease-in-out infinite;
}
.pSkillItemHitbox:hover .stackB {
    width: 6vh;
    height: 5vh;
    animation: stack2 3s 0.4s ease-in-out infinite;
}
.pSkillItemHitbox:hover .stackC {
    height: 8vh;
    width: 4vh;
    right: 10vh;
    animation: stack3 3s 0.6s ease-in-out infinite;
}
.pSkillItemHitbox:hover .stackD {
    animation: stack4 3s 0.8s ease-in-out infinite;
    width: 6vh;
}
.pSkillItemHitbox:hover .stackE {
    animation: stack5 3s 1s ease-in-out infinite;
}
.musicA, .musicB, .musicC, .musicD, .musicE {
    width: 12%;
    height: 0%;
    position: absolute;
    bottom: 0;
    left: 20%;
    background-color: #0003;
    transition: all 0.25s linear;
}
.musicB { left: 32%; }
.musicC { left: 44%; }
.musicD { left: 56%; }
.musicE { left: 68%; }

.sectionWrap:has(.skillGrid) h4 {
    position: absolute;
    left: 12.5%;
    text-align: left;
    width: 75%;
}

.pSkillItemWrap {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #636363;
    text-decoration: none;
    color: var(--l-txt);
    border-radius: 3vh;
    display: flex;
    -webkit-user-drag: none;
    transition: all 1s;
    box-shadow: -2vh 2vh 0 #0003;
    overflow: hidden;
}
.pSkillItemHitbox:hover .pSkillItemWrap {
    background-color: var(--hover);
}
.pSkillItemHitbox .pSkillItemWrap:has(.panelA) {
    background-color: transparent;
}

.pSkillImg {
    height: 100%;
    width: 100%;
    transition: all 1s;
}
.pSkillItem {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    backdrop-filter: blur(0px);
    border-radius: 3vh;
    transition: box-shadow 0.25s 0.25s;
}

.pSkillItem h5 {
    font-size: 5vh;
    position: relative;
    top: 5vh;
    text-shadow: none;
}

.pSkillItem p {
    width: 80%;
    text-shadow: none;
}

.pSkillItem .pSkillContent::selection, .pSkillItem h5::selection {
    background-color: var(--l-bkg);
    color: var(--d-txt);
}


.sectionWrap:has(.skillGrid) {
    padding-left: 0;
    padding-right: 0;
    background-color: var(--d-bkg);
}

.pSkillGridScroll {
    width: 20%;
    height: 80vh;
    position: absolute;
    top: 20vh;
    left: 0;
    z-index: 100;
    background: transparent url(../media/icons/leftArrow.svg);
    background-size: 75%;
    background-position: right 30%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.25s;
    filter: opacity(40%) invert(1);
}
.pSkillGridScroll.right {
    background-image: url(../media/icons/rightArrow.svg) !important;
    background-position: left 30%;
    left: unset;
    right: 0;
}
.pSkillGridScroll:hover {
    background: transparent url(../media/icons/leftArrow.svg) 30% 30% / 70% no-repeat;
    filter: opacity(100%) invert(1);
}
.pSkillGridScroll.right:hover { background: transparent url(../media/icons/rightArrow.svg) 70% 30% / 70% no-repeat; }

.pSkillGridScroll:active {
    background: transparent url(../media/icons/leftArrow.svg) 25% 30% / 60% no-repeat;
    filter: opacity(50%) invert(1);
}
.pSkillGridScroll.right:active { background: transparent url(../media/icons/rightArrow.svg) 75% 30% / 60% no-repeat; }
.pSkillItemHitbox:hover .manageA {
    animation: computer1 5s ease-in-out 0.25s infinite normal forwards;
}
.pSkillItemHitbox:hover .manageA, .pSkillItemHitbox:hover .manageB, .pSkillItemHitbox:hover .manageC, .pSkillItemHitbox:hover .manageD {
    transform: scale(0%);
    width: 8vh;
    height: 8vh;
    position: absolute;
    top: 0;
    right: 8vh;
    background: transparent url(../media/icons/computer.svg) center center / contain no-repeat;
}
.pSkillItemHitbox:hover .manageB, .pSkillItemHitbox:hover .manageC {
    right: 2vh;
    top: 14vh;
    animation: computer2 5s ease-in-out 0.5s infinite normal forwards;
}
.pSkillItemHitbox:hover .manageC {
    right: 14vh;
    animation-delay: 0.75s;
}
.pSkillItemHitbox:hover .manageD {
    top: 7vh;
    background: transparent url(../media/icons/networkNode.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    animation: hub 5s ease-in-out 0.75s infinite;
}
.pSkillItemHitbox:hover .lightbulbA,
.pSkillItemHitbox:hover .lightbulbB,
.pSkillItemHitbox:hover .lightbulbC {
    height: 10vh;
    width: 10vh;
    position: absolute;
    bottom: -10vh;
    right: 10vh;
    background-color: green;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 100%;
    animation: capThrow1 2s ease-in 0s infinite;
}
.pSkillItemHitbox:hover .lightbulbA > div,
.pSkillItemHitbox:hover .lightbulbB > div,
.pSkillItemHitbox:hover .lightbulbC > div {
    content: url(../media/icons/learn.svg);
    height: 10vh;
    width: 10vh;
    position: relative;
    bottom: 0;
    right: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    animation: capThrow2 2s ease-out 0s infinite;
}
.pSkillItemHitbox:hover .lightbulbB {
    right: 12vh;
    animation-delay: 0.5s;
}
.pSkillItemHitbox:hover .lightbulbB > div {
    animation-delay: 0.5s;
}
.pSkillItemHitbox:hover .lightbulbC {
    right: 5vh;
    animation-delay: 0.4s;
}
.pSkillItemHitbox:hover .lightbulbC > div {
    animation-delay: 0.4s;
}
.pSkillItemHitbox:hover .lightbulbD {
    height: 10vh;
    width: 10vh;
    background: transparent url(../media/icons/lightbulb.svg);
    position: absolute;
    top: 6vh;
    left: 5vh;
    transform-origin: 50% 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    animation: bulbShake 4s ease-in-out infinite;
}
.pSkillItemHitbox:hover .lightbulbE {
    height: 20vh;
    width: 20vh;
    border-radius: 100%;
    position: absolute;
    background: transparent radial-gradient(#fff8, #fff3);
    animation: flicker 4s ease-in-out infinite;
}