.tac { text-align: center; }
.h-centered {margin: auto;}
.flex, .flexWrapWrap { display: flex; flex-wrap: wrap; }
.flexWrapNowrap { display: flex; flex-wrap: nowrap; }
.flb1 { flex-basis: 1%; }
.flb2 { flex-basis: 2%; }
.flb3 { flex-basis: 3%; }
.flb4 { flex-basis: 4%; }
.flb5 { flex-basis: 5%; }
.flb6 { flex-basis: 6%; }
.flb7 { flex-basis: 7%; }
.flb8 { flex-basis: 8%; }
.flb9 { flex-basis: 9%; }
.flb10 { flex-basis: 10%; }
.flb11 { flex-basis: 11%; }
.flb12 { flex-basis: 12%; }
.flb13 { flex-basis: 13%; }
.flb14 { flex-basis: 14%; }
.flb15 { flex-basis: 15%; }
.flb16 { flex-basis: 16%; }
.flb17 { flex-basis: 17%; }
.flb18 { flex-basis: 18%; }
.flb19 { flex-basis: 19%; }
.flb20 { flex-basis: 20%; }
.flb21 { flex-basis: 21%; }
.flb22 { flex-basis: 22%; }
.flb23 { flex-basis: 23%; }
.flb24 { flex-basis: 24%; }
.flb25 { flex-basis: 25%; }
.flb26 { flex-basis: 26%; }
.flb27 { flex-basis: 27%; }
.flb28 { flex-basis: 28%; }
.flb29 { flex-basis: 29%; }
.flb30 { flex-basis: 30%; }
.flb31 { flex-basis: 31%; }
.flb32 { flex-basis: 32%; }
.flb33 { flex-basis: 33%; }
.flb34 { flex-basis: 34%; }
.flb35 { flex-basis: 35%; }
.flb36 { flex-basis: 36%; }
.flb37 { flex-basis: 37%; }
.flb38 { flex-basis: 38%; }
.flb39 { flex-basis: 39%; }
.flb40 { flex-basis: 40%; }
.flb41 { flex-basis: 41%; }
.flb42 { flex-basis: 42%; }
.flb43 { flex-basis: 43%; }
.flb44 { flex-basis: 44%; }
.flb45 { flex-basis: 45%; }
.flb46 { flex-basis: 46%; }
.flb47 { flex-basis: 47%; }
.flb48 { flex-basis: 48%; }
.flb49 { flex-basis: 49%; }
.flb50 { flex-basis: 50%; }
.flb51 { flex-basis: 51%; }
.flb52 { flex-basis: 52%; }
.flb53 { flex-basis: 53%; }
.flb54 { flex-basis: 54%; }
.flb55 { flex-basis: 55%; }
.flb56 { flex-basis: 56%; }
.flb57 { flex-basis: 57%; }
.flb58 { flex-basis: 58%; }
.flb59 { flex-basis: 59%; }
.flb60 { flex-basis: 60%; }
.flb61 { flex-basis: 61%; }
.flb62 { flex-basis: 62%; }
.flb63 { flex-basis: 63%; }
.flb64 { flex-basis: 64%; }
.flb65 { flex-basis: 65%; }
.flb66 { flex-basis: 66%; }
.flb67 { flex-basis: 67%; }
.flb68 { flex-basis: 68%; }
.flb69 { flex-basis: 69%; }
.flb70 { flex-basis: 70%; }
.flb71 { flex-basis: 71%; }
.flb72 { flex-basis: 72%; }
.flb73 { flex-basis: 73%; }
.flb74 { flex-basis: 74%; }
.flb75 { flex-basis: 75%; }
.flb76 { flex-basis: 76%; }
.flb77 { flex-basis: 77%; }
.flb78 { flex-basis: 78%; }
.flb79 { flex-basis: 79%; }
.flb80 { flex-basis: 80%; }
.flb81 { flex-basis: 81%; }
.flb82 { flex-basis: 82%; }
.flb83 { flex-basis: 83%; }
.flb84 { flex-basis: 84%; }
.flb85 { flex-basis: 85%; }
.flb86 { flex-basis: 86%; }
.flb87 { flex-basis: 87%; }
.flb88 { flex-basis: 88%; }
.flb89 { flex-basis: 89%; }
.flb90 { flex-basis: 90%; }
.flb91 { flex-basis: 91%; }
.flb92 { flex-basis: 92%; }
.flb93 { flex-basis: 93%; }
.flb94 { flex-basis: 94%; }
.flb95 { flex-basis: 95%; }
.flb96 { flex-basis: 96%; }
.flb97 { flex-basis: 97%; }
.flb98 { flex-basis: 98%; }
.flb99 { flex-basis: 99%; }
.flb100 { flex-basis: 100%; }


.grid { display: grid; column-gap: 5vh; row-gap: 5vh; }
.gtc1 { grid-template-columns: repeat(1, 1fr); }
.gtc2 { grid-template-columns: repeat(2, 1fr); }
.gtc3 { grid-template-columns: repeat(3, 1fr); }
.gtc4 { grid-template-columns: repeat(4, 1fr); }
.gtc5 { grid-template-columns: repeat(5, 1fr); }
.gtc6 { grid-template-columns: repeat(6, 1fr); }
.gtc7 { grid-template-columns: repeat(7, 1fr); }
.gtc8 { grid-template-columns: repeat(8, 1fr); }
.gtc9 { grid-template-columns: repeat(9, 1fr); }
.gtc10 { grid-template-columns: repeat(10, 1fr); }
.gtc11 { grid-template-columns: repeat(11, 1fr); }
.gtc12 { grid-template-columns: repeat(12, 1fr); }
.gtc13 { grid-template-columns: repeat(13, 1fr); }
.gtc14 { grid-template-columns: repeat(14, 1fr); }
.gtc15 { grid-template-columns: repeat(15, 1fr); }
.gtc16 { grid-template-columns: repeat(16, 1fr); }
.gtc17 { grid-template-columns: repeat(17, 1fr); }
.gtc18 { grid-template-columns: repeat(18, 1fr); }
.gtc19 { grid-template-columns: repeat(19, 1fr); }
.gtc20 { grid-template-columns: repeat(20, 1fr); }
.gtc21 { grid-template-columns: repeat(21, 1fr); }
.gtc22 { grid-template-columns: repeat(22, 1fr); }
.gtc23 { grid-template-columns: repeat(23, 1fr); }
.gtc24 { grid-template-columns: repeat(24, 1fr); }
.gtc25 { grid-template-columns: repeat(25, 1fr); }
.gtc26 { grid-template-columns: repeat(26, 1fr); }
.gtc27 { grid-template-columns: repeat(27, 1fr); }
.gtc28 { grid-template-columns: repeat(28, 1fr); }
.gtc29 { grid-template-columns: repeat(29, 1fr); }
.gtc30 { grid-template-columns: repeat(30, 1fr); }
.gtc31 { grid-template-columns: repeat(31, 1fr); }
.gtc32 { grid-template-columns: repeat(32, 1fr); }
.gtc33 { grid-template-columns: repeat(33, 1fr); }
.gtc34 { grid-template-columns: repeat(34, 1fr); }
.gtc35 { grid-template-columns: repeat(35, 1fr); }
.gtc36 { grid-template-columns: repeat(36, 1fr); }
.gtc37 { grid-template-columns: repeat(37, 1fr); }
.gtc38 { grid-template-columns: repeat(38, 1fr); }
.gtc39 { grid-template-columns: repeat(39, 1fr); }
.gtc40 { grid-template-columns: repeat(40, 1fr); }
.gtc41 { grid-template-columns: repeat(41, 1fr); }
.gtc42 { grid-template-columns: repeat(42, 1fr); }
.gtc43 { grid-template-columns: repeat(43, 1fr); }
.gtc44 { grid-template-columns: repeat(44, 1fr); }
.gtc45 { grid-template-columns: repeat(45, 1fr); }
.gtc46 { grid-template-columns: repeat(46, 1fr); }
.gtc47 { grid-template-columns: repeat(47, 1fr); }
.gtc48 { grid-template-columns: repeat(48, 1fr); }
.gtc49 { grid-template-columns: repeat(49, 1fr); }
.gtc50 { grid-template-columns: repeat(50, 1fr); }
.gtc51 { grid-template-columns: repeat(51, 1fr); }
.gtc52 { grid-template-columns: repeat(52, 1fr); }
.gtc53 { grid-template-columns: repeat(53, 1fr); }
.gtc54 { grid-template-columns: repeat(54, 1fr); }
.gtc55 { grid-template-columns: repeat(55, 1fr); }
.gtc56 { grid-template-columns: repeat(56, 1fr); }
.gtc57 { grid-template-columns: repeat(57, 1fr); }
.gtc58 { grid-template-columns: repeat(58, 1fr); }
.gtc59 { grid-template-columns: repeat(59, 1fr); }
.gtc60 { grid-template-columns: repeat(60, 1fr); }
.gtc61 { grid-template-columns: repeat(61, 1fr); }
.gtc62 { grid-template-columns: repeat(62, 1fr); }
.gtc63 { grid-template-columns: repeat(63, 1fr); }
.gtc64 { grid-template-columns: repeat(64, 1fr); }
.gtc65 { grid-template-columns: repeat(65, 1fr); }
.gtc66 { grid-template-columns: repeat(66, 1fr); }
.gtc67 { grid-template-columns: repeat(67, 1fr); }
.gtc68 { grid-template-columns: repeat(68, 1fr); }
.gtc69 { grid-template-columns: repeat(69, 1fr); }
.gtc70 { grid-template-columns: repeat(70, 1fr); }
.gtc71 { grid-template-columns: repeat(71, 1fr); }
.gtc72 { grid-template-columns: repeat(72, 1fr); }
.gtc73 { grid-template-columns: repeat(73, 1fr); }
.gtc74 { grid-template-columns: repeat(74, 1fr); }
.gtc75 { grid-template-columns: repeat(75, 1fr); }
.gtc76 { grid-template-columns: repeat(76, 1fr); }
.gtc77 { grid-template-columns: repeat(77, 1fr); }
.gtc78 { grid-template-columns: repeat(78, 1fr); }
.gtc79 { grid-template-columns: repeat(79, 1fr); }
.gtc80 { grid-template-columns: repeat(80, 1fr); }
.gtc81 { grid-template-columns: repeat(81, 1fr); }
.gtc82 { grid-template-columns: repeat(82, 1fr); }
.gtc83 { grid-template-columns: repeat(83, 1fr); }
.gtc84 { grid-template-columns: repeat(84, 1fr); }
.gtc85 { grid-template-columns: repeat(85, 1fr); }
.gtc86 { grid-template-columns: repeat(86, 1fr); }
.gtc87 { grid-template-columns: repeat(87, 1fr); }
.gtc88 { grid-template-columns: repeat(88, 1fr); }
.gtc89 { grid-template-columns: repeat(89, 1fr); }
.gtc90 { grid-template-columns: repeat(90, 1fr); }
.gtc91 { grid-template-columns: repeat(91, 1fr); }
.gtc92 { grid-template-columns: repeat(92, 1fr); }
.gtc93 { grid-template-columns: repeat(93, 1fr); }
.gtc94 { grid-template-columns: repeat(94, 1fr); }
.gtc95 { grid-template-columns: repeat(95, 1fr); }
.gtc96 { grid-template-columns: repeat(96, 1fr); }
.gtc97 { grid-template-columns: repeat(97, 1fr); }
.gtc98 { grid-template-columns: repeat(98, 1fr); }
.gtc99 { grid-template-columns: repeat(99, 1fr); }
.gtc100 { grid-template-columns: repeat(100, 1fr); }
.gtr1 { grid-template-rows: repeat(1, 1fr); }
.gtr2 { grid-template-rows: repeat(2, 1fr); }
.gtr3 { grid-template-rows: repeat(3, 1fr); }
.gtr4 { grid-template-rows: repeat(4, 1fr); }
.gtr5 { grid-template-rows: repeat(5, 1fr); }
.gtr6 { grid-template-rows: repeat(6, 1fr); }
.gtr7 { grid-template-rows: repeat(7, 1fr); }
.gtr8 { grid-template-rows: repeat(8, 1fr); }
.gtr9 { grid-template-rows: repeat(9, 1fr); }
.gtr10 { grid-template-rows: repeat(10, 1fr); }
.gtr11 { grid-template-rows: repeat(11, 1fr); }
.gtr12 { grid-template-rows: repeat(12, 1fr); }
.gtr13 { grid-template-rows: repeat(13, 1fr); }
.gtr14 { grid-template-rows: repeat(14, 1fr); }
.gtr15 { grid-template-rows: repeat(15, 1fr); }
.gtr16 { grid-template-rows: repeat(16, 1fr); }
.gtr17 { grid-template-rows: repeat(17, 1fr); }
.gtr18 { grid-template-rows: repeat(18, 1fr); }
.gtr19 { grid-template-rows: repeat(19, 1fr); }
.gtr20 { grid-template-rows: repeat(20, 1fr); }
.gtr21 { grid-template-rows: repeat(21, 1fr); }
.gtr22 { grid-template-rows: repeat(22, 1fr); }
.gtr23 { grid-template-rows: repeat(23, 1fr); }
.gtr24 { grid-template-rows: repeat(24, 1fr); }
.gtr25 { grid-template-rows: repeat(25, 1fr); }
.gtr26 { grid-template-rows: repeat(26, 1fr); }
.gtr27 { grid-template-rows: repeat(27, 1fr); }
.gtr28 { grid-template-rows: repeat(28, 1fr); }
.gtr29 { grid-template-rows: repeat(29, 1fr); }
.gtr30 { grid-template-rows: repeat(30, 1fr); }
.gtr31 { grid-template-rows: repeat(31, 1fr); }
.gtr32 { grid-template-rows: repeat(32, 1fr); }
.gtr33 { grid-template-rows: repeat(33, 1fr); }
.gtr34 { grid-template-rows: repeat(34, 1fr); }
.gtr35 { grid-template-rows: repeat(35, 1fr); }
.gtr36 { grid-template-rows: repeat(36, 1fr); }
.gtr37 { grid-template-rows: repeat(37, 1fr); }
.gtr38 { grid-template-rows: repeat(38, 1fr); }
.gtr39 { grid-template-rows: repeat(39, 1fr); }
.gtr40 { grid-template-rows: repeat(40, 1fr); }
.gtr41 { grid-template-rows: repeat(41, 1fr); }
.gtr42 { grid-template-rows: repeat(42, 1fr); }
.gtr43 { grid-template-rows: repeat(43, 1fr); }
.gtr44 { grid-template-rows: repeat(44, 1fr); }
.gtr45 { grid-template-rows: repeat(45, 1fr); }
.gtr46 { grid-template-rows: repeat(46, 1fr); }
.gtr47 { grid-template-rows: repeat(47, 1fr); }
.gtr48 { grid-template-rows: repeat(48, 1fr); }
.gtr49 { grid-template-rows: repeat(49, 1fr); }
.gtr50 { grid-template-rows: repeat(50, 1fr); }
.gtr51 { grid-template-rows: repeat(51, 1fr); }
.gtr52 { grid-template-rows: repeat(52, 1fr); }
.gtr53 { grid-template-rows: repeat(53, 1fr); }
.gtr54 { grid-template-rows: repeat(54, 1fr); }
.gtr55 { grid-template-rows: repeat(55, 1fr); }
.gtr56 { grid-template-rows: repeat(56, 1fr); }
.gtr57 { grid-template-rows: repeat(57, 1fr); }
.gtr58 { grid-template-rows: repeat(58, 1fr); }
.gtr59 { grid-template-rows: repeat(59, 1fr); }
.gtr60 { grid-template-rows: repeat(60, 1fr); }
.gtr61 { grid-template-rows: repeat(61, 1fr); }
.gtr62 { grid-template-rows: repeat(62, 1fr); }
.gtr63 { grid-template-rows: repeat(63, 1fr); }
.gtr64 { grid-template-rows: repeat(64, 1fr); }
.gtr65 { grid-template-rows: repeat(65, 1fr); }
.gtr66 { grid-template-rows: repeat(66, 1fr); }
.gtr67 { grid-template-rows: repeat(67, 1fr); }
.gtr68 { grid-template-rows: repeat(68, 1fr); }
.gtr69 { grid-template-rows: repeat(69, 1fr); }
.gtr70 { grid-template-rows: repeat(70, 1fr); }
.gtr71 { grid-template-rows: repeat(71, 1fr); }
.gtr72 { grid-template-rows: repeat(72, 1fr); }
.gtr73 { grid-template-rows: repeat(73, 1fr); }
.gtr74 { grid-template-rows: repeat(74, 1fr); }
.gtr75 { grid-template-rows: repeat(75, 1fr); }
.gtr76 { grid-template-rows: repeat(76, 1fr); }
.gtr77 { grid-template-rows: repeat(77, 1fr); }
.gtr78 { grid-template-rows: repeat(78, 1fr); }
.gtr79 { grid-template-rows: repeat(79, 1fr); }
.gtr80 { grid-template-rows: repeat(80, 1fr); }
.gtr81 { grid-template-rows: repeat(81, 1fr); }
.gtr82 { grid-template-rows: repeat(82, 1fr); }
.gtr83 { grid-template-rows: repeat(83, 1fr); }
.gtr84 { grid-template-rows: repeat(84, 1fr); }
.gtr85 { grid-template-rows: repeat(85, 1fr); }
.gtr86 { grid-template-rows: repeat(86, 1fr); }
.gtr87 { grid-template-rows: repeat(87, 1fr); }
.gtr88 { grid-template-rows: repeat(88, 1fr); }
.gtr89 { grid-template-rows: repeat(89, 1fr); }
.gtr90 { grid-template-rows: repeat(90, 1fr); }
.gtr91 { grid-template-rows: repeat(91, 1fr); }
.gtr92 { grid-template-rows: repeat(92, 1fr); }
.gtr93 { grid-template-rows: repeat(93, 1fr); }
.gtr94 { grid-template-rows: repeat(94, 1fr); }
.gtr95 { grid-template-rows: repeat(95, 1fr); }
.gtr96 { grid-template-rows: repeat(96, 1fr); }
.gtr97 { grid-template-rows: repeat(97, 1fr); }
.gtr98 { grid-template-rows: repeat(98, 1fr); }
.gtr99 { grid-template-rows: repeat(99, 1fr); }
.gtr100 { grid-template-rows: repeat(100, 1fr); }

.mt1 { margin-top: 1vh; }
.mt2 { margin-top: 2vh; }
.mt3 { margin-top: 3vh; }
.mt4 { margin-top: 4vh; }
.mt5 { margin-top: 5vh; }
.mt6 { margin-top: 6vh; }
.mt7 { margin-top: 7vh; }
.mt8 { margin-top: 8vh; }
.mt9 { margin-top: 9vh; }
.mt10 { margin-top: 10vh; }
.mt11 { margin-top: 11vh; }
.mt12 { margin-top: 12vh; }
.mt13 { margin-top: 13vh; }
.mt14 { margin-top: 14vh; }
.mt15 { margin-top: 15vh; }
.mt16 { margin-top: 16vh; }
.mt17 { margin-top: 17vh; }
.mt18 { margin-top: 18vh; }
.mt19 { margin-top: 19vh; }
.mt20 { margin-top: 20vh; }
.mt21 { margin-top: 21vh; }
.mt22 { margin-top: 22vh; }
.mt23 { margin-top: 23vh; }
.mt24 { margin-top: 24vh; }
.mt25 { margin-top: 25vh; }
.mt26 { margin-top: 26vh; }
.mt27 { margin-top: 27vh; }
.mt28 { margin-top: 28vh; }
.mt29 { margin-top: 29vh; }
.mt30 { margin-top: 30vh; }
.mt31 { margin-top: 31vh; }
.mt32 { margin-top: 32vh; }
.mt33 { margin-top: 33vh; }
.mt34 { margin-top: 34vh; }
.mt35 { margin-top: 35vh; }
.mt36 { margin-top: 36vh; }
.mt37 { margin-top: 37vh; }
.mt38 { margin-top: 38vh; }
.mt39 { margin-top: 39vh; }
.mt40 { margin-top: 40vh; }
.mt41 { margin-top: 41vh; }
.mt42 { margin-top: 42vh; }
.mt43 { margin-top: 43vh; }
.mt44 { margin-top: 44vh; }
.mt45 { margin-top: 45vh; }
.mt46 { margin-top: 46vh; }
.mt47 { margin-top: 47vh; }
.mt48 { margin-top: 48vh; }
.mt49 { margin-top: 49vh; }
.mt50 { margin-top: 50vh; }
.mt51 { margin-top: 51vh; }
.mt52 { margin-top: 52vh; }
.mt53 { margin-top: 53vh; }
.mt54 { margin-top: 54vh; }
.mt55 { margin-top: 55vh; }
.mt56 { margin-top: 56vh; }
.mt57 { margin-top: 57vh; }
.mt58 { margin-top: 58vh; }
.mt59 { margin-top: 59vh; }
.mt60 { margin-top: 60vh; }
.mt61 { margin-top: 61vh; }
.mt62 { margin-top: 62vh; }
.mt63 { margin-top: 63vh; }
.mt64 { margin-top: 64vh; }
.mt65 { margin-top: 65vh; }
.mt66 { margin-top: 66vh; }
.mt67 { margin-top: 67vh; }
.mt68 { margin-top: 68vh; }
.mt69 { margin-top: 69vh; }
.mt70 { margin-top: 70vh; }
.mt71 { margin-top: 71vh; }
.mt72 { margin-top: 72vh; }
.mt73 { margin-top: 73vh; }
.mt74 { margin-top: 74vh; }
.mt75 { margin-top: 75vh; }
.mt76 { margin-top: 76vh; }
.mt77 { margin-top: 77vh; }
.mt78 { margin-top: 78vh; }
.mt79 { margin-top: 79vh; }
.mt80 { margin-top: 80vh; }
.mt81 { margin-top: 81vh; }
.mt82 { margin-top: 82vh; }
.mt83 { margin-top: 83vh; }
.mt84 { margin-top: 84vh; }
.mt85 { margin-top: 85vh; }
.mt86 { margin-top: 86vh; }
.mt87 { margin-top: 87vh; }
.mt88 { margin-top: 88vh; }
.mt89 { margin-top: 89vh; }
.mt90 { margin-top: 90vh; }
.mt91 { margin-top: 91vh; }
.mt92 { margin-top: 92vh; }
.mt93 { margin-top: 93vh; }
.mt94 { margin-top: 94vh; }
.mt95 { margin-top: 95vh; }
.mt96 { margin-top: 96vh; }
.mt97 { margin-top: 97vh; }
.mt98 { margin-top: 98vh; }
.mt99 { margin-top: 99vh; }
.mt100 { margin-top: 100vh; }

.mb1 { margin-bottom: 1vh; }
.mb2 { margin-bottom: 2vh; }
.mb3 { margin-bottom: 3vh; }
.mb4 { margin-bottom: 4vh; }
.mb5 { margin-bottom: 5vh; }
.mb6 { margin-bottom: 6vh; }
.mb7 { margin-bottom: 7vh; }
.mb8 { margin-bottom: 8vh; }
.mb9 { margin-bottom: 9vh; }
.mb10 { margin-bottom: 10vh; }
.mb11 { margin-bottom: 11vh; }
.mb12 { margin-bottom: 12vh; }
.mb13 { margin-bottom: 13vh; }
.mb14 { margin-bottom: 14vh; }
.mb15 { margin-bottom: 15vh; }
.mb16 { margin-bottom: 16vh; }
.mb17 { margin-bottom: 17vh; }
.mb18 { margin-bottom: 18vh; }
.mb19 { margin-bottom: 19vh; }
.mb20 { margin-bottom: 20vh; }
.mb21 { margin-bottom: 21vh; }
.mb22 { margin-bottom: 22vh; }
.mb23 { margin-bottom: 23vh; }
.mb24 { margin-bottom: 24vh; }
.mb25 { margin-bottom: 25vh; }
.mb26 { margin-bottom: 26vh; }
.mb27 { margin-bottom: 27vh; }
.mb28 { margin-bottom: 28vh; }
.mb29 { margin-bottom: 29vh; }
.mb30 { margin-bottom: 30vh; }
.mb31 { margin-bottom: 31vh; }
.mb32 { margin-bottom: 32vh; }
.mb33 { margin-bottom: 33vh; }
.mb34 { margin-bottom: 34vh; }
.mb35 { margin-bottom: 35vh; }
.mb36 { margin-bottom: 36vh; }
.mb37 { margin-bottom: 37vh; }
.mb38 { margin-bottom: 38vh; }
.mb39 { margin-bottom: 39vh; }
.mb40 { margin-bottom: 40vh; }
.mb41 { margin-bottom: 41vh; }
.mb42 { margin-bottom: 42vh; }
.mb43 { margin-bottom: 43vh; }
.mb44 { margin-bottom: 44vh; }
.mb45 { margin-bottom: 45vh; }
.mb46 { margin-bottom: 46vh; }
.mb47 { margin-bottom: 47vh; }
.mb48 { margin-bottom: 48vh; }
.mb49 { margin-bottom: 49vh; }
.mb50 { margin-bottom: 50vh; }
.mb51 { margin-bottom: 51vh; }
.mb52 { margin-bottom: 52vh; }
.mb53 { margin-bottom: 53vh; }
.mb54 { margin-bottom: 54vh; }
.mb55 { margin-bottom: 55vh; }
.mb56 { margin-bottom: 56vh; }
.mb57 { margin-bottom: 57vh; }
.mb58 { margin-bottom: 58vh; }
.mb59 { margin-bottom: 59vh; }
.mb60 { margin-bottom: 60vh; }
.mb61 { margin-bottom: 61vh; }
.mb62 { margin-bottom: 62vh; }
.mb63 { margin-bottom: 63vh; }
.mb64 { margin-bottom: 64vh; }
.mb65 { margin-bottom: 65vh; }
.mb66 { margin-bottom: 66vh; }
.mb67 { margin-bottom: 67vh; }
.mb68 { margin-bottom: 68vh; }
.mb69 { margin-bottom: 69vh; }
.mb70 { margin-bottom: 70vh; }
.mb71 { margin-bottom: 71vh; }
.mb72 { margin-bottom: 72vh; }
.mb73 { margin-bottom: 73vh; }
.mb74 { margin-bottom: 74vh; }
.mb75 { margin-bottom: 75vh; }
.mb76 { margin-bottom: 76vh; }
.mb77 { margin-bottom: 77vh; }
.mb78 { margin-bottom: 78vh; }
.mb79 { margin-bottom: 79vh; }
.mb80 { margin-bottom: 80vh; }
.mb81 { margin-bottom: 81vh; }
.mb82 { margin-bottom: 82vh; }
.mb83 { margin-bottom: 83vh; }
.mb84 { margin-bottom: 84vh; }
.mb85 { margin-bottom: 85vh; }
.mb86 { margin-bottom: 86vh; }
.mb87 { margin-bottom: 87vh; }
.mb88 { margin-bottom: 88vh; }
.mb89 { margin-bottom: 89vh; }
.mb90 { margin-bottom: 90vh; }
.mb91 { margin-bottom: 91vh; }
.mb92 { margin-bottom: 92vh; }
.mb93 { margin-bottom: 93vh; }
.mb94 { margin-bottom: 94vh; }
.mb95 { margin-bottom: 95vh; }
.mb96 { margin-bottom: 96vh; }
.mb97 { margin-bottom: 97vh; }
.mb98 { margin-bottom: 98vh; }
.mb99 { margin-bottom: 99vh; }
.mb100 { margin-bottom: 100vh; }

.ml1 { margin-left: 1vh; }
.ml2 { margin-left: 2vh; }
.ml3 { margin-left: 3vh; }
.ml4 { margin-left: 4vh; }
.ml5 { margin-left: 5vh; }
.ml6 { margin-left: 6vh; }
.ml7 { margin-left: 7vh; }
.ml8 { margin-left: 8vh; }
.ml9 { margin-left: 9vh; }
.ml10 { margin-left: 10vh; }
.ml11 { margin-left: 11vh; }
.ml12 { margin-left: 12vh; }
.ml13 { margin-left: 13vh; }
.ml14 { margin-left: 14vh; }
.ml15 { margin-left: 15vh; }
.ml16 { margin-left: 16vh; }
.ml17 { margin-left: 17vh; }
.ml18 { margin-left: 18vh; }
.ml19 { margin-left: 19vh; }
.ml20 { margin-left: 20vh; }
.ml21 { margin-left: 21vh; }
.ml22 { margin-left: 22vh; }
.ml23 { margin-left: 23vh; }
.ml24 { margin-left: 24vh; }
.ml25 { margin-left: 25vh; }
.ml26 { margin-left: 26vh; }
.ml27 { margin-left: 27vh; }
.ml28 { margin-left: 28vh; }
.ml29 { margin-left: 29vh; }
.ml30 { margin-left: 30vh; }
.ml31 { margin-left: 31vh; }
.ml32 { margin-left: 32vh; }
.ml33 { margin-left: 33vh; }
.ml34 { margin-left: 34vh; }
.ml35 { margin-left: 35vh; }
.ml36 { margin-left: 36vh; }
.ml37 { margin-left: 37vh; }
.ml38 { margin-left: 38vh; }
.ml39 { margin-left: 39vh; }
.ml40 { margin-left: 40vh; }
.ml41 { margin-left: 41vh; }
.ml42 { margin-left: 42vh; }
.ml43 { margin-left: 43vh; }
.ml44 { margin-left: 44vh; }
.ml45 { margin-left: 45vh; }
.ml46 { margin-left: 46vh; }
.ml47 { margin-left: 47vh; }
.ml48 { margin-left: 48vh; }
.ml49 { margin-left: 49vh; }
.ml50 { margin-left: 50vh; }
.ml51 { margin-left: 51vh; }
.ml52 { margin-left: 52vh; }
.ml53 { margin-left: 53vh; }
.ml54 { margin-left: 54vh; }
.ml55 { margin-left: 55vh; }
.ml56 { margin-left: 56vh; }
.ml57 { margin-left: 57vh; }
.ml58 { margin-left: 58vh; }
.ml59 { margin-left: 59vh; }
.ml60 { margin-left: 60vh; }
.ml61 { margin-left: 61vh; }
.ml62 { margin-left: 62vh; }
.ml63 { margin-left: 63vh; }
.ml64 { margin-left: 64vh; }
.ml65 { margin-left: 65vh; }
.ml66 { margin-left: 66vh; }
.ml67 { margin-left: 67vh; }
.ml68 { margin-left: 68vh; }
.ml69 { margin-left: 69vh; }
.ml70 { margin-left: 70vh; }
.ml71 { margin-left: 71vh; }
.ml72 { margin-left: 72vh; }
.ml73 { margin-left: 73vh; }
.ml74 { margin-left: 74vh; }
.ml75 { margin-left: 75vh; }
.ml76 { margin-left: 76vh; }
.ml77 { margin-left: 77vh; }
.ml78 { margin-left: 78vh; }
.ml79 { margin-left: 79vh; }
.ml80 { margin-left: 80vh; }
.ml81 { margin-left: 81vh; }
.ml82 { margin-left: 82vh; }
.ml83 { margin-left: 83vh; }
.ml84 { margin-left: 84vh; }
.ml85 { margin-left: 85vh; }
.ml86 { margin-left: 86vh; }
.ml87 { margin-left: 87vh; }
.ml88 { margin-left: 88vh; }
.ml89 { margin-left: 89vh; }
.ml90 { margin-left: 90vh; }
.ml91 { margin-left: 91vh; }
.ml92 { margin-left: 92vh; }
.ml93 { margin-left: 93vh; }
.ml94 { margin-left: 94vh; }
.ml95 { margin-left: 95vh; }
.ml96 { margin-left: 96vh; }
.ml97 { margin-left: 97vh; }
.ml98 { margin-left: 98vh; }
.ml99 { margin-left: 99vh; }
.ml100 { margin-left: 100vh; }

.mr1 { margin-right: 1vh; }
.mr2 { margin-right: 2vh; }
.mr3 { margin-right: 3vh; }
.mr4 { margin-right: 4vh; }
.mr5 { margin-right: 5vh; }
.mr6 { margin-right: 6vh; }
.mr7 { margin-right: 7vh; }
.mr8 { margin-right: 8vh; }
.mr9 { margin-right: 9vh; }
.mr10 { margin-right: 10vh; }
.mr11 { margin-right: 11vh; }
.mr12 { margin-right: 12vh; }
.mr13 { margin-right: 13vh; }
.mr14 { margin-right: 14vh; }
.mr15 { margin-right: 15vh; }
.mr16 { margin-right: 16vh; }
.mr17 { margin-right: 17vh; }
.mr18 { margin-right: 18vh; }
.mr19 { margin-right: 19vh; }
.mr20 { margin-right: 20vh; }
.mr21 { margin-right: 21vh; }
.mr22 { margin-right: 22vh; }
.mr23 { margin-right: 23vh; }
.mr24 { margin-right: 24vh; }
.mr25 { margin-right: 25vh; }
.mr26 { margin-right: 26vh; }
.mr27 { margin-right: 27vh; }
.mr28 { margin-right: 28vh; }
.mr29 { margin-right: 29vh; }
.mr30 { margin-right: 30vh; }
.mr31 { margin-right: 31vh; }
.mr32 { margin-right: 32vh; }
.mr33 { margin-right: 33vh; }
.mr34 { margin-right: 34vh; }
.mr35 { margin-right: 35vh; }
.mr36 { margin-right: 36vh; }
.mr37 { margin-right: 37vh; }
.mr38 { margin-right: 38vh; }
.mr39 { margin-right: 39vh; }
.mr40 { margin-right: 40vh; }
.mr41 { margin-right: 41vh; }
.mr42 { margin-right: 42vh; }
.mr43 { margin-right: 43vh; }
.mr44 { margin-right: 44vh; }
.mr45 { margin-right: 45vh; }
.mr46 { margin-right: 46vh; }
.mr47 { margin-right: 47vh; }
.mr48 { margin-right: 48vh; }
.mr49 { margin-right: 49vh; }
.mr50 { margin-right: 50vh; }
.mr51 { margin-right: 51vh; }
.mr52 { margin-right: 52vh; }
.mr53 { margin-right: 53vh; }
.mr54 { margin-right: 54vh; }
.mr55 { margin-right: 55vh; }
.mr56 { margin-right: 56vh; }
.mr57 { margin-right: 57vh; }
.mr58 { margin-right: 58vh; }
.mr59 { margin-right: 59vh; }
.mr60 { margin-right: 60vh; }
.mr61 { margin-right: 61vh; }
.mr62 { margin-right: 62vh; }
.mr63 { margin-right: 63vh; }
.mr64 { margin-right: 64vh; }
.mr65 { margin-right: 65vh; }
.mr66 { margin-right: 66vh; }
.mr67 { margin-right: 67vh; }
.mr68 { margin-right: 68vh; }
.mr69 { margin-right: 69vh; }
.mr70 { margin-right: 70vh; }
.mr71 { margin-right: 71vh; }
.mr72 { margin-right: 72vh; }
.mr73 { margin-right: 73vh; }
.mr74 { margin-right: 74vh; }
.mr75 { margin-right: 75vh; }
.mr76 { margin-right: 76vh; }
.mr77 { margin-right: 77vh; }
.mr78 { margin-right: 78vh; }
.mr79 { margin-right: 79vh; }
.mr80 { margin-right: 80vh; }
.mr81 { margin-right: 81vh; }
.mr82 { margin-right: 82vh; }
.mr83 { margin-right: 83vh; }
.mr84 { margin-right: 84vh; }
.mr85 { margin-right: 85vh; }
.mr86 { margin-right: 86vh; }
.mr87 { margin-right: 87vh; }
.mr88 { margin-right: 88vh; }
.mr89 { margin-right: 89vh; }
.mr90 { margin-right: 90vh; }
.mr91 { margin-right: 91vh; }
.mr92 { margin-right: 92vh; }
.mr93 { margin-right: 93vh; }
.mr94 { margin-right: 94vh; }
.mr95 { margin-right: 95vh; }
.mr96 { margin-right: 96vh; }
.mr97 { margin-right: 97vh; }
.mr98 { margin-right: 98vh; }
.mr99 { margin-right: 99vh; }
.mr100 { margin-right: 100vh; }

.pt1 { padding-top: 1vh; }
.pt2 { padding-top: 2vh; }
.pt3 { padding-top: 3vh; }
.pt4 { padding-top: 4vh; }
.pt5 { padding-top: 5vh; }
.pt6 { padding-top: 6vh; }
.pt7 { padding-top: 7vh; }
.pt8 { padding-top: 8vh; }
.pt9 { padding-top: 9vh; }
.pt10 { padding-top: 10vh; }
.pt11 { padding-top: 11vh; }
.pt12 { padding-top: 12vh; }
.pt13 { padding-top: 13vh; }
.pt14 { padding-top: 14vh; }
.pt15 { padding-top: 15vh; }
.pt16 { padding-top: 16vh; }
.pt17 { padding-top: 17vh; }
.pt18 { padding-top: 18vh; }
.pt19 { padding-top: 19vh; }
.pt20 { padding-top: 20vh; }
.pt21 { padding-top: 21vh; }
.pt22 { padding-top: 22vh; }
.pt23 { padding-top: 23vh; }
.pt24 { padding-top: 24vh; }
.pt25 { padding-top: 25vh; }
.pt26 { padding-top: 26vh; }
.pt27 { padding-top: 27vh; }
.pt28 { padding-top: 28vh; }
.pt29 { padding-top: 29vh; }
.pt30 { padding-top: 30vh; }
.pt31 { padding-top: 31vh; }
.pt32 { padding-top: 32vh; }
.pt33 { padding-top: 33vh; }
.pt34 { padding-top: 34vh; }
.pt35 { padding-top: 35vh; }
.pt36 { padding-top: 36vh; }
.pt37 { padding-top: 37vh; }
.pt38 { padding-top: 38vh; }
.pt39 { padding-top: 39vh; }
.pt40 { padding-top: 40vh; }
.pt41 { padding-top: 41vh; }
.pt42 { padding-top: 42vh; }
.pt43 { padding-top: 43vh; }
.pt44 { padding-top: 44vh; }
.pt45 { padding-top: 45vh; }
.pt46 { padding-top: 46vh; }
.pt47 { padding-top: 47vh; }
.pt48 { padding-top: 48vh; }
.pt49 { padding-top: 49vh; }
.pt50 { padding-top: 50vh; }
.pt51 { padding-top: 51vh; }
.pt52 { padding-top: 52vh; }
.pt53 { padding-top: 53vh; }
.pt54 { padding-top: 54vh; }
.pt55 { padding-top: 55vh; }
.pt56 { padding-top: 56vh; }
.pt57 { padding-top: 57vh; }
.pt58 { padding-top: 58vh; }
.pt59 { padding-top: 59vh; }
.pt60 { padding-top: 60vh; }
.pt61 { padding-top: 61vh; }
.pt62 { padding-top: 62vh; }
.pt63 { padding-top: 63vh; }
.pt64 { padding-top: 64vh; }
.pt65 { padding-top: 65vh; }
.pt66 { padding-top: 66vh; }
.pt67 { padding-top: 67vh; }
.pt68 { padding-top: 68vh; }
.pt69 { padding-top: 69vh; }
.pt70 { padding-top: 70vh; }
.pt71 { padding-top: 71vh; }
.pt72 { padding-top: 72vh; }
.pt73 { padding-top: 73vh; }
.pt74 { padding-top: 74vh; }
.pt75 { padding-top: 75vh; }
.pt76 { padding-top: 76vh; }
.pt77 { padding-top: 77vh; }
.pt78 { padding-top: 78vh; }
.pt79 { padding-top: 79vh; }
.pt80 { padding-top: 80vh; }
.pt81 { padding-top: 81vh; }
.pt82 { padding-top: 82vh; }
.pt83 { padding-top: 83vh; }
.pt84 { padding-top: 84vh; }
.pt85 { padding-top: 85vh; }
.pt86 { padding-top: 86vh; }
.pt87 { padding-top: 87vh; }
.pt88 { padding-top: 88vh; }
.pt89 { padding-top: 89vh; }
.pt90 { padding-top: 90vh; }
.pt91 { padding-top: 91vh; }
.pt92 { padding-top: 92vh; }
.pt93 { padding-top: 93vh; }
.pt94 { padding-top: 94vh; }
.pt95 { padding-top: 95vh; }
.pt96 { padding-top: 96vh; }
.pt97 { padding-top: 97vh; }
.pt98 { padding-top: 98vh; }
.pt99 { padding-top: 99vh; }
.pt100 { padding-top: 100vh; }

.pb1 { padding-bottom: 1vh; }
.pb2 { padding-bottom: 2vh; }
.pb3 { padding-bottom: 3vh; }
.pb4 { padding-bottom: 4vh; }
.pb5 { padding-bottom: 5vh; }
.pb6 { padding-bottom: 6vh; }
.pb7 { padding-bottom: 7vh; }
.pb8 { padding-bottom: 8vh; }
.pb9 { padding-bottom: 9vh; }
.pb10 { padding-bottom: 10vh; }
.pb11 { padding-bottom: 11vh; }
.pb12 { padding-bottom: 12vh; }
.pb13 { padding-bottom: 13vh; }
.pb14 { padding-bottom: 14vh; }
.pb15 { padding-bottom: 15vh; }
.pb16 { padding-bottom: 16vh; }
.pb17 { padding-bottom: 17vh; }
.pb18 { padding-bottom: 18vh; }
.pb19 { padding-bottom: 19vh; }
.pb20 { padding-bottom: 20vh; }
.pb21 { padding-bottom: 21vh; }
.pb22 { padding-bottom: 22vh; }
.pb23 { padding-bottom: 23vh; }
.pb24 { padding-bottom: 24vh; }
.pb25 { padding-bottom: 25vh; }
.pb26 { padding-bottom: 26vh; }
.pb27 { padding-bottom: 27vh; }
.pb28 { padding-bottom: 28vh; }
.pb29 { padding-bottom: 29vh; }
.pb30 { padding-bottom: 30vh; }
.pb31 { padding-bottom: 31vh; }
.pb32 { padding-bottom: 32vh; }
.pb33 { padding-bottom: 33vh; }
.pb34 { padding-bottom: 34vh; }
.pb35 { padding-bottom: 35vh; }
.pb36 { padding-bottom: 36vh; }
.pb37 { padding-bottom: 37vh; }
.pb38 { padding-bottom: 38vh; }
.pb39 { padding-bottom: 39vh; }
.pb40 { padding-bottom: 40vh; }
.pb41 { padding-bottom: 41vh; }
.pb42 { padding-bottom: 42vh; }
.pb43 { padding-bottom: 43vh; }
.pb44 { padding-bottom: 44vh; }
.pb45 { padding-bottom: 45vh; }
.pb46 { padding-bottom: 46vh; }
.pb47 { padding-bottom: 47vh; }
.pb48 { padding-bottom: 48vh; }
.pb49 { padding-bottom: 49vh; }
.pb50 { padding-bottom: 50vh; }
.pb51 { padding-bottom: 51vh; }
.pb52 { padding-bottom: 52vh; }
.pb53 { padding-bottom: 53vh; }
.pb54 { padding-bottom: 54vh; }
.pb55 { padding-bottom: 55vh; }
.pb56 { padding-bottom: 56vh; }
.pb57 { padding-bottom: 57vh; }
.pb58 { padding-bottom: 58vh; }
.pb59 { padding-bottom: 59vh; }
.pb60 { padding-bottom: 60vh; }
.pb61 { padding-bottom: 61vh; }
.pb62 { padding-bottom: 62vh; }
.pb63 { padding-bottom: 63vh; }
.pb64 { padding-bottom: 64vh; }
.pb65 { padding-bottom: 65vh; }
.pb66 { padding-bottom: 66vh; }
.pb67 { padding-bottom: 67vh; }
.pb68 { padding-bottom: 68vh; }
.pb69 { padding-bottom: 69vh; }
.pb70 { padding-bottom: 70vh; }
.pb71 { padding-bottom: 71vh; }
.pb72 { padding-bottom: 72vh; }
.pb73 { padding-bottom: 73vh; }
.pb74 { padding-bottom: 74vh; }
.pb75 { padding-bottom: 75vh; }
.pb76 { padding-bottom: 76vh; }
.pb77 { padding-bottom: 77vh; }
.pb78 { padding-bottom: 78vh; }
.pb79 { padding-bottom: 79vh; }
.pb80 { padding-bottom: 80vh; }
.pb81 { padding-bottom: 81vh; }
.pb82 { padding-bottom: 82vh; }
.pb83 { padding-bottom: 83vh; }
.pb84 { padding-bottom: 84vh; }
.pb85 { padding-bottom: 85vh; }
.pb86 { padding-bottom: 86vh; }
.pb87 { padding-bottom: 87vh; }
.pb88 { padding-bottom: 88vh; }
.pb89 { padding-bottom: 89vh; }
.pb90 { padding-bottom: 90vh; }
.pb91 { padding-bottom: 91vh; }
.pb92 { padding-bottom: 92vh; }
.pb93 { padding-bottom: 93vh; }
.pb94 { padding-bottom: 94vh; }
.pb95 { padding-bottom: 95vh; }
.pb96 { padding-bottom: 96vh; }
.pb97 { padding-bottom: 97vh; }
.pb98 { padding-bottom: 98vh; }
.pb99 { padding-bottom: 99vh; }
.pb100 { padding-bottom: 100vh; }

.pl1 { padding-left: 1vh; }
.pl2 { padding-left: 2vh; }
.pl3 { padding-left: 3vh; }
.pl4 { padding-left: 4vh; }
.pl5 { padding-left: 5vh; }
.pl6 { padding-left: 6vh; }
.pl7 { padding-left: 7vh; }
.pl8 { padding-left: 8vh; }
.pl9 { padding-left: 9vh; }
.pl10 { padding-left: 10vh; }
.pl11 { padding-left: 11vh; }
.pl12 { padding-left: 12vh; }
.pl13 { padding-left: 13vh; }
.pl14 { padding-left: 14vh; }
.pl15 { padding-left: 15vh; }
.pl16 { padding-left: 16vh; }
.pl17 { padding-left: 17vh; }
.pl18 { padding-left: 18vh; }
.pl19 { padding-left: 19vh; }
.pl20 { padding-left: 20vh; }
.pl21 { padding-left: 21vh; }
.pl22 { padding-left: 22vh; }
.pl23 { padding-left: 23vh; }
.pl24 { padding-left: 24vh; }
.pl25 { padding-left: 25vh; }
.pl26 { padding-left: 26vh; }
.pl27 { padding-left: 27vh; }
.pl28 { padding-left: 28vh; }
.pl29 { padding-left: 29vh; }
.pl30 { padding-left: 30vh; }
.pl31 { padding-left: 31vh; }
.pl32 { padding-left: 32vh; }
.pl33 { padding-left: 33vh; }
.pl34 { padding-left: 34vh; }
.pl35 { padding-left: 35vh; }
.pl36 { padding-left: 36vh; }
.pl37 { padding-left: 37vh; }
.pl38 { padding-left: 38vh; }
.pl39 { padding-left: 39vh; }
.pl40 { padding-left: 40vh; }
.pl41 { padding-left: 41vh; }
.pl42 { padding-left: 42vh; }
.pl43 { padding-left: 43vh; }
.pl44 { padding-left: 44vh; }
.pl45 { padding-left: 45vh; }
.pl46 { padding-left: 46vh; }
.pl47 { padding-left: 47vh; }
.pl48 { padding-left: 48vh; }
.pl49 { padding-left: 49vh; }
.pl50 { padding-left: 50vh; }
.pl51 { padding-left: 51vh; }
.pl52 { padding-left: 52vh; }
.pl53 { padding-left: 53vh; }
.pl54 { padding-left: 54vh; }
.pl55 { padding-left: 55vh; }
.pl56 { padding-left: 56vh; }
.pl57 { padding-left: 57vh; }
.pl58 { padding-left: 58vh; }
.pl59 { padding-left: 59vh; }
.pl60 { padding-left: 60vh; }
.pl61 { padding-left: 61vh; }
.pl62 { padding-left: 62vh; }
.pl63 { padding-left: 63vh; }
.pl64 { padding-left: 64vh; }
.pl65 { padding-left: 65vh; }
.pl66 { padding-left: 66vh; }
.pl67 { padding-left: 67vh; }
.pl68 { padding-left: 68vh; }
.pl69 { padding-left: 69vh; }
.pl70 { padding-left: 70vh; }
.pl71 { padding-left: 71vh; }
.pl72 { padding-left: 72vh; }
.pl73 { padding-left: 73vh; }
.pl74 { padding-left: 74vh; }
.pl75 { padding-left: 75vh; }
.pl76 { padding-left: 76vh; }
.pl77 { padding-left: 77vh; }
.pl78 { padding-left: 78vh; }
.pl79 { padding-left: 79vh; }
.pl80 { padding-left: 80vh; }
.pl81 { padding-left: 81vh; }
.pl82 { padding-left: 82vh; }
.pl83 { padding-left: 83vh; }
.pl84 { padding-left: 84vh; }
.pl85 { padding-left: 85vh; }
.pl86 { padding-left: 86vh; }
.pl87 { padding-left: 87vh; }
.pl88 { padding-left: 88vh; }
.pl89 { padding-left: 89vh; }
.pl90 { padding-left: 90vh; }
.pl91 { padding-left: 91vh; }
.pl92 { padding-left: 92vh; }
.pl93 { padding-left: 93vh; }
.pl94 { padding-left: 94vh; }
.pl95 { padding-left: 95vh; }
.pl96 { padding-left: 96vh; }
.pl97 { padding-left: 97vh; }
.pl98 { padding-left: 98vh; }
.pl99 { padding-left: 99vh; }
.pl100 { padding-left: 100vh; }

.pr1 { padding-right: 1vh; }
.pr2 { padding-right: 2vh; }
.pr3 { padding-right: 3vh; }
.pr4 { padding-right: 4vh; }
.pr5 { padding-right: 5vh; }
.pr6 { padding-right: 6vh; }
.pr7 { padding-right: 7vh; }
.pr8 { padding-right: 8vh; }
.pr9 { padding-right: 9vh; }
.pr10 { padding-right: 10vh; }
.pr11 { padding-right: 11vh; }
.pr12 { padding-right: 12vh; }
.pr13 { padding-right: 13vh; }
.pr14 { padding-right: 14vh; }
.pr15 { padding-right: 15vh; }
.pr16 { padding-right: 16vh; }
.pr17 { padding-right: 17vh; }
.pr18 { padding-right: 18vh; }
.pr19 { padding-right: 19vh; }
.pr20 { padding-right: 20vh; }
.pr21 { padding-right: 21vh; }
.pr22 { padding-right: 22vh; }
.pr23 { padding-right: 23vh; }
.pr24 { padding-right: 24vh; }
.pr25 { padding-right: 25vh; }
.pr26 { padding-right: 26vh; }
.pr27 { padding-right: 27vh; }
.pr28 { padding-right: 28vh; }
.pr29 { padding-right: 29vh; }
.pr30 { padding-right: 30vh; }
.pr31 { padding-right: 31vh; }
.pr32 { padding-right: 32vh; }
.pr33 { padding-right: 33vh; }
.pr34 { padding-right: 34vh; }
.pr35 { padding-right: 35vh; }
.pr36 { padding-right: 36vh; }
.pr37 { padding-right: 37vh; }
.pr38 { padding-right: 38vh; }
.pr39 { padding-right: 39vh; }
.pr40 { padding-right: 40vh; }
.pr41 { padding-right: 41vh; }
.pr42 { padding-right: 42vh; }
.pr43 { padding-right: 43vh; }
.pr44 { padding-right: 44vh; }
.pr45 { padding-right: 45vh; }
.pr46 { padding-right: 46vh; }
.pr47 { padding-right: 47vh; }
.pr48 { padding-right: 48vh; }
.pr49 { padding-right: 49vh; }
.pr50 { padding-right: 50vh; }
.pr51 { padding-right: 51vh; }
.pr52 { padding-right: 52vh; }
.pr53 { padding-right: 53vh; }
.pr54 { padding-right: 54vh; }
.pr55 { padding-right: 55vh; }
.pr56 { padding-right: 56vh; }
.pr57 { padding-right: 57vh; }
.pr58 { padding-right: 58vh; }
.pr59 { padding-right: 59vh; }
.pr60 { padding-right: 60vh; }
.pr61 { padding-right: 61vh; }
.pr62 { padding-right: 62vh; }
.pr63 { padding-right: 63vh; }
.pr64 { padding-right: 64vh; }
.pr65 { padding-right: 65vh; }
.pr66 { padding-right: 66vh; }
.pr67 { padding-right: 67vh; }
.pr68 { padding-right: 68vh; }
.pr69 { padding-right: 69vh; }
.pr70 { padding-right: 70vh; }
.pr71 { padding-right: 71vh; }
.pr72 { padding-right: 72vh; }
.pr73 { padding-right: 73vh; }
.pr74 { padding-right: 74vh; }
.pr75 { padding-right: 75vh; }
.pr76 { padding-right: 76vh; }
.pr77 { padding-right: 77vh; }
.pr78 { padding-right: 78vh; }
.pr79 { padding-right: 79vh; }
.pr80 { padding-right: 80vh; }
.pr81 { padding-right: 81vh; }
.pr82 { padding-right: 82vh; }
.pr83 { padding-right: 83vh; }
.pr84 { padding-right: 84vh; }
.pr85 { padding-right: 85vh; }
.pr86 { padding-right: 86vh; }
.pr87 { padding-right: 87vh; }
.pr88 { padding-right: 88vh; }
.pr89 { padding-right: 89vh; }
.pr90 { padding-right: 90vh; }
.pr91 { padding-right: 91vh; }
.pr92 { padding-right: 92vh; }
.pr93 { padding-right: 93vh; }
.pr94 { padding-right: 94vh; }
.pr95 { padding-right: 95vh; }
.pr96 { padding-right: 96vh; }
.pr97 { padding-right: 97vh; }
.pr98 { padding-right: 98vh; }
.pr99 { padding-right: 99vh; }
.pr100 { padding-right: 100vh; }

.w1p { width: 1%; }
.w2p { width: 2%; }
.w3p { width: 3%; }
.w4p { width: 4%; }
.w5p { width: 5%; }
.w6p { width: 6%; }
.w7p { width: 7%; }
.w8p { width: 8%; }
.w9p { width: 9%; }
.w10p { width: 10%; }
.w11p { width: 11%; }
.w12p { width: 12%; }
.w13p { width: 13%; }
.w14p { width: 14%; }
.w15p { width: 15%; }
.w16p { width: 16%; }
.w17p { width: 17%; }
.w18p { width: 18%; }
.w19p { width: 19%; }
.w20p { width: 20%; }
.w21p { width: 21%; }
.w22p { width: 22%; }
.w23p { width: 23%; }
.w24p { width: 24%; }
.w25p { width: 25%; }
.w26p { width: 26%; }
.w27p { width: 27%; }
.w28p { width: 28%; }
.w29p { width: 29%; }
.w30p { width: 30%; }
.w31p { width: 31%; }
.w32p { width: 32%; }
.w33p { width: 33%; }
.w34p { width: 34%; }
.w35p { width: 35%; }
.w36p { width: 36%; }
.w37p { width: 37%; }
.w38p { width: 38%; }
.w39p { width: 39%; }
.w40p { width: 40%; }
.w41p { width: 41%; }
.w42p { width: 42%; }
.w43p { width: 43%; }
.w44p { width: 44%; }
.w45p { width: 45%; }
.w46p { width: 46%; }
.w47p { width: 47%; }
.w48p { width: 48%; }
.w49p { width: 49%; }
.w50p { width: 50%; }
.w51p { width: 51%; }
.w52p { width: 52%; }
.w53p { width: 53%; }
.w54p { width: 54%; }
.w55p { width: 55%; }
.w56p { width: 56%; }
.w57p { width: 57%; }
.w58p { width: 58%; }
.w59p { width: 59%; }
.w60p { width: 60%; }
.w61p { width: 61%; }
.w62p { width: 62%; }
.w63p { width: 63%; }
.w64p { width: 64%; }
.w65p { width: 65%; }
.w66p { width: 66%; }
.w67p { width: 67%; }
.w68p { width: 68%; }
.w69p { width: 69%; }
.w70p { width: 70%; }
.w71p { width: 71%; }
.w72p { width: 72%; }
.w73p { width: 73%; }
.w74p { width: 74%; }
.w75p { width: 75%; }
.w76p { width: 76%; }
.w77p { width: 77%; }
.w78p { width: 78%; }
.w79p { width: 79%; }
.w80p { width: 80%; }
.w81p { width: 81%; }
.w82p { width: 82%; }
.w83p { width: 83%; }
.w84p { width: 84%; }
.w85p { width: 85%; }
.w86p { width: 86%; }
.w87p { width: 87%; }
.w88p { width: 88%; }
.w89p { width: 89%; }
.w90p { width: 90%; }
.w91p { width: 91%; }
.w92p { width: 92%; }
.w93p { width: 93%; }
.w94p { width: 94%; }
.w95p { width: 95%; }
.w96p { width: 96%; }
.w97p { width: 97%; }
.w98p { width: 98%; }
.w99p { width: 99%; }
.w100p { width: 100%; }

.h1p { height: 1%; }
.h2p { height: 2%; }
.h3p { height: 3%; }
.h4p { height: 4%; }
.h5p { height: 5%; }
.h6p { height: 6%; }
.h7p { height: 7%; }
.h8p { height: 8%; }
.h9p { height: 9%; }
.h10p { height: 10%; }
.h11p { height: 11%; }
.h12p { height: 12%; }
.h13p { height: 13%; }
.h14p { height: 14%; }
.h15p { height: 15%; }
.h16p { height: 16%; }
.h17p { height: 17%; }
.h18p { height: 18%; }
.h19p { height: 19%; }
.h20p { height: 20%; }
.h21p { height: 21%; }
.h22p { height: 22%; }
.h23p { height: 23%; }
.h24p { height: 24%; }
.h25p { height: 25%; }
.h26p { height: 26%; }
.h27p { height: 27%; }
.h28p { height: 28%; }
.h29p { height: 29%; }
.h30p { height: 30%; }
.h31p { height: 31%; }
.h32p { height: 32%; }
.h33p { height: 33%; }
.h34p { height: 34%; }
.h35p { height: 35%; }
.h36p { height: 36%; }
.h37p { height: 37%; }
.h38p { height: 38%; }
.h39p { height: 39%; }
.h40p { height: 40%; }
.h41p { height: 41%; }
.h42p { height: 42%; }
.h43p { height: 43%; }
.h44p { height: 44%; }
.h45p { height: 45%; }
.h46p { height: 46%; }
.h47p { height: 47%; }
.h48p { height: 48%; }
.h49p { height: 49%; }
.h50p { height: 50%; }
.h51p { height: 51%; }
.h52p { height: 52%; }
.h53p { height: 53%; }
.h54p { height: 54%; }
.h55p { height: 55%; }
.h56p { height: 56%; }
.h57p { height: 57%; }
.h58p { height: 58%; }
.h59p { height: 59%; }
.h60p { height: 60%; }
.h61p { height: 61%; }
.h62p { height: 62%; }
.h63p { height: 63%; }
.h64p { height: 64%; }
.h65p { height: 65%; }
.h66p { height: 66%; }
.h67p { height: 67%; }
.h68p { height: 68%; }
.h69p { height: 69%; }
.h70p { height: 70%; }
.h71p { height: 71%; }
.h72p { height: 72%; }
.h73p { height: 73%; }
.h74p { height: 74%; }
.h75p { height: 75%; }
.h76p { height: 76%; }
.h77p { height: 77%; }
.h78p { height: 78%; }
.h79p { height: 79%; }
.h80p { height: 80%; }
.h81p { height: 81%; }
.h82p { height: 82%; }
.h83p { height: 83%; }
.h84p { height: 84%; }
.h85p { height: 85%; }
.h86p { height: 86%; }
.h87p { height: 87%; }
.h88p { height: 88%; }
.h89p { height: 89%; }
.h90p { height: 90%; }
.h91p { height: 91%; }
.h92p { height: 92%; }
.h93p { height: 93%; }
.h94p { height: 94%; }
.h95p { height: 95%; }
.h96p { height: 96%; }
.h97p { height: 97%; }
.h98p { height: 98%; }
.h99p { height: 99%; }
.h100p { height: 100%; }

.wMin { width: min-content; }
.wMax { width: max-content; }
.wFit { width: fit-content; }
.w1v { width: 1vw; }
.w2v { width: 2vw; }
.w3v { width: 3vw; }
.w4v { width: 4vw; }
.w5v { width: 5vw; }
.w6v { width: 6vw; }
.w7v { width: 7vw; }
.w8v { width: 8vw; }
.w9v { width: 9vw; }
.w10v { width: 10vw; }
.w11v { width: 11vw; }
.w12v { width: 12vw; }
.w13v { width: 13vw; }
.w14v { width: 14vw; }
.w15v { width: 15vw; }
.w16v { width: 16vw; }
.w17v { width: 17vw; }
.w18v { width: 18vw; }
.w19v { width: 19vw; }
.w20v { width: 20vw; }
.w21v { width: 21vw; }
.w22v { width: 22vw; }
.w23v { width: 23vw; }
.w24v { width: 24vw; }
.w25v { width: 25vw; }
.w26v { width: 26vw; }
.w27v { width: 27vw; }
.w28v { width: 28vw; }
.w29v { width: 29vw; }
.w30v { width: 30vw; }
.w31v { width: 31vw; }
.w32v { width: 32vw; }
.w33v { width: 33vw; }
.w34v { width: 34vw; }
.w35v { width: 35vw; }
.w36v { width: 36vw; }
.w37v { width: 37vw; }
.w38v { width: 38vw; }
.w39v { width: 39vw; }
.w40v { width: 40vw; }
.w41v { width: 41vw; }
.w42v { width: 42vw; }
.w43v { width: 43vw; }
.w44v { width: 44vw; }
.w45v { width: 45vw; }
.w46v { width: 46vw; }
.w47v { width: 47vw; }
.w48v { width: 48vw; }
.w49v { width: 49vw; }
.w50v { width: 50vw; }
.w51v { width: 51vw; }
.w52v { width: 52vw; }
.w53v { width: 53vw; }
.w54v { width: 54vw; }
.w55v { width: 55vw; }
.w56v { width: 56vw; }
.w57v { width: 57vw; }
.w58v { width: 58vw; }
.w59v { width: 59vw; }
.w60v { width: 60vw; }
.w61v { width: 61vw; }
.w62v { width: 62vw; }
.w63v { width: 63vw; }
.w64v { width: 64vw; }
.w65v { width: 65vw; }
.w66v { width: 66vw; }
.w67v { width: 67vw; }
.w68v { width: 68vw; }
.w69v { width: 69vw; }
.w70v { width: 70vw; }
.w71v { width: 71vw; }
.w72v { width: 72vw; }
.w73v { width: 73vw; }
.w74v { width: 74vw; }
.w75v { width: 75vw; }
.w76v { width: 76vw; }
.w77v { width: 77vw; }
.w78v { width: 78vw; }
.w79v { width: 79vw; }
.w80v { width: 80vw; }
.w81v { width: 81vw; }
.w82v { width: 82vw; }
.w83v { width: 83vw; }
.w84v { width: 84vw; }
.w85v { width: 85vw; }
.w86v { width: 86vw; }
.w87v { width: 87vw; }
.w88v { width: 88vw; }
.w89v { width: 89vw; }
.w90v { width: 90vw; }
.w91v { width: 91vw; }
.w92v { width: 92vw; }
.w93v { width: 93vw; }
.w94v { width: 94vw; }
.w95v { width: 95vw; }
.w96v { width: 96vw; }
.w97v { width: 97vw; }
.w98v { width: 98vw; }
.w99v { width: 99vw; }
.w100v { width: 100vw; }

.hMin { height: min-content; }
.hMax { height: max-content; }
.hFit { height: fit-content; }
.h1v { height: 1vh; }
.h2v { height: 2vh; }
.h3v { height: 3vh; }
.h4v { height: 4vh; }
.h5v { height: 5vh; }
.h6v { height: 6vh; }
.h7v { height: 7vh; }
.h8v { height: 8vh; }
.h9v { height: 9vh; }
.h10v { height: 10vh; }
.h11v { height: 11vh; }
.h12v { height: 12vh; }
.h13v { height: 13vh; }
.h14v { height: 14vh; }
.h15v { height: 15vh; }
.h16v { height: 16vh; }
.h17v { height: 17vh; }
.h18v { height: 18vh; }
.h19v { height: 19vh; }
.h20v { height: 20vh; }
.h21v { height: 21vh; }
.h22v { height: 22vh; }
.h23v { height: 23vh; }
.h24v { height: 24vh; }
.h25v { height: 25vh; }
.h26v { height: 26vh; }
.h27v { height: 27vh; }
.h28v { height: 28vh; }
.h29v { height: 29vh; }
.h30v { height: 30vh; }
.h31v { height: 31vh; }
.h32v { height: 32vh; }
.h33v { height: 33vh; }
.h34v { height: 34vh; }
.h35v { height: 35vh; }
.h36v { height: 36vh; }
.h37v { height: 37vh; }
.h38v { height: 38vh; }
.h39v { height: 39vh; }
.h40v { height: 40vh; }
.h41v { height: 41vh; }
.h42v { height: 42vh; }
.h43v { height: 43vh; }
.h44v { height: 44vh; }
.h45v { height: 45vh; }
.h46v { height: 46vh; }
.h47v { height: 47vh; }
.h48v { height: 48vh; }
.h49v { height: 49vh; }
.h50v { height: 50vh; }
.h51v { height: 51vh; }
.h52v { height: 52vh; }
.h53v { height: 53vh; }
.h54v { height: 54vh; }
.h55v { height: 55vh; }
.h56v { height: 56vh; }
.h57v { height: 57vh; }
.h58v { height: 58vh; }
.h59v { height: 59vh; }
.h60v { height: 60vh; }
.h61v { height: 61vh; }
.h62v { height: 62vh; }
.h63v { height: 63vh; }
.h64v { height: 64vh; }
.h65v { height: 65vh; }
.h66v { height: 66vh; }
.h67v { height: 67vh; }
.h68v { height: 68vh; }
.h69v { height: 69vh; }
.h70v { height: 70vh; }
.h71v { height: 71vh; }
.h72v { height: 72vh; }
.h73v { height: 73vh; }
.h74v { height: 74vh; }
.h75v { height: 75vh; }
.h76v { height: 76vh; }
.h77v { height: 77vh; }
.h78v { height: 78vh; }
.h79v { height: 79vh; }
.h80v { height: 80vh; }
.h81v { height: 81vh; }
.h82v { height: 82vh; }
.h83v { height: 83vh; }
.h84v { height: 84vh; }
.h85v { height: 85vh; }
.h86v { height: 86vh; }
.h87v { height: 87vh; }
.h88v { height: 88vh; }
.h89v { height: 89vh; }
.h90v { height: 90vh; }
.h91v { height: 91vh; }
.h92v { height: 92vh; }
.h93v { height: 93vh; }
.h94v { height: 94vh; }
.h95v { height: 95vh; }
.h96v { height: 96vh; }
.h97v { height: 97vh; }
.h98v { height: 98vh; }
.h99v { height: 99vh; }
.h100v { height: 100vh; }