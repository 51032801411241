header {
    height: 12vh;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    user-select: none;
    position: relative;
    z-index: 3;
    box-shadow: 0 0.5vh 0.5vh #0004;
}
.headerWrapper {
    flex-grow: 1;
    cursor: pointer;
}
.headerWrapper { background-color: var(--l-head); }
.dmode .headerWrapper { background-color: var(--d-head); }
.title {
    height: 11vh;
    font-size: 7vh;
    text-align: center;
    font-weight: bold;
}
.titleHoverBar { background-color: var(--d-txt); }
.dmode .titleHoverBar { background-color: var(--l-txt); }

.titleHoverBar {
    height: 1vh;
    width: 100%;
    overflow: hidden;
}

.hoverColor {
    transform: rotate(45deg);
    position: relative;
    top: -80vh;
    left: -12vw;
    width: 100%;
    height: 100vh;
    background-color: var(--hover);
    background-clip: content-box;
    transition: 0.25s linear;
}
.modeBtn:hover .hoverColor,
.navBtnWrapper:hover .hoverColor { top: -40vh; }
.modeBtn .hoverColor { top: -50vh; }
.modeBtn:hover .hoverColor { top: -30vh; }


nav {
    height: 11vh;
    width: 65%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.navBtnWrapper {
    background-color: var(--l-head);
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    transition: 0.25s;
}
.dmode .navBtnWrapper { background-color: var(--d-head); }

.navBtnWrapper:hover { background-color: var(--l-hover); cursor: pointer; }
.dmode .navBtnWrapper:hover { background-color: var(--d-hover); }
.navBtn {
    width: 100%;
    height: 11vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navBtnHoverBar {
    background-color: var(--d-txt); 
    width: 100%;
    height: 1vh;
    overflow: hidden;
}
.dmode .navBtnHoverBar { background-color: var(--l-txt); }


.modeSwitch {
    box-shadow: -0.25vh 0.25vh 0.5vh #000 inset;
    position: relative;
    top: 0vh;
    background-color: var(--d-head);
    height: 3vh;
    width: 3vh;
    z-index: 2;
    border-radius: 100%;
    transform: translateX(-55%);
}
.dmode .modeSwitch {
    box-shadow: -0.25vh 0.25vh 0.5vh #FFF inset;
    background-color: var(--l-head);
    transform: translateX(55%) rotate(360deg);
}
.modeSwitch .modeSwitchInverter {
    background-color: var(--l-head);
    height: 100%;
    width: 50%;
    position: relative;
    transform: translateX(50%);
    clip-path: circle(40% at 0% 50%);
}
.dmode .modeSwitchInverter {
    background-color: var(--d-head);
}
.modeBtnBar {
    background-color: var(--d-hover);
    box-shadow: 0.25vh -0.25vh 0.5vh #000 inset;
    width: 4vh;
    height: 2vh;
    border-radius: 1vh;
    position: absolute;
    right: 4vh;
    top: 4.5vh;
    z-index: 1;
    filter: opacity(50%);
}
.dmode .modeBtnBar {
    background-color: var(--l-head);
    box-shadow: 0.25vh -0.25vh 0.5vh #FFF inset;
}

.dmode .modeBtn { background-color: var(--d-head); }
.modeBtn {
    width: 12vh;
    height: 12vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    user-select: none;
    transition: 0.25s;
    border: none;
    cursor: pointer;
    background-color: var(--l-head);
}
.modeBtnHoverBar { background-color: var(--d-txt); }
.dmode .modeBtnHoverBar { background-color: var(--l-txt); }
.modeBtnHoverBar {
    width: 100%;
    height: 1vh;
    overflow: hidden;
}
.modeBtn:hover { background-color: var(--l-hover); }
.dmode .modeBtn:hover { background-color: var(--d-hover); }
.modeBtnWrap {
    position: relative;
    z-index: 2;
    height: 11vh;
    width: 11vh;
    border: none;
    overflow: hidden;
    transform: scale(25%) translateX(-25%);
}

.lmode .modeBtn .modeBtnWrap {
    transform: scale(50%) translate(25%) rotate(360deg);
}


.modeBtnWrap * { background: var(--d-txt); }
.moonInner { background: var(--l-head); }
.modeBtn:hover .moonInner { background: var(--l-hover); }
.dmode .modeBtnWrap * { background: var(--l-txt); }
.dmode .modeBtn:hover .moonInner { background: var(--d-hover); }
.dmode .moonInner { background: #0000; }


.title, .navBtn { color: var(--d-txt); }
.dmode .title, .dmode .navBtn { color: var(--l-txt); }
.title, .navBtn { color: var(--hover); }
header * { transition: 0.25s linear !important; }

.dmode .titleHoverBar {
    background: 
    linear-gradient(-45deg, var(--l-txt) 55%, var(--hover) 0) 
    var(--_p,100%)/200% no-repeat;
    color: #0000;
    transition: 0.25s;
}

/* ----------------------------------------------------------------------------------- Footer -------------------------------------------------------------------------------------- */
footer {
    width: 100%;
    height: 12vh;
    display: flex;
    flex-wrap: nowrap;
    font-size: 2vh;
    user-select: none;
}
.addressWrapper { background-color: var(--l-head); }
.dmode .addressWrapper { background-color: var(--d-head); }
footer .addressWrapper {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
footer > * {
    height: 12vh;
}
footer .navBtnWrapper:hover .hoverColor {top: -35vh;}